import React, { useEffect, useState } from 'react';
import DynamicModal from '../ui/modal/DynamicModal';
import PrimaryButton from '../ui/buttons/PrimaryButton';
import MDSubtitleText from '../ui/typography/MDSubtitleText';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import '../../styles/pages/incoming-invoices.scss';
import TimeClockIcon from '../../images/icons/time-clock.svg';
import XSText from '../ui/typography/XSText';
import Avatar from '../ui/Avatar';
import { Divider, Radio } from '@mui/material';
import * as importedInvoiceReducer from '../importedInvoices/reduxStore/action';
import { API_CODE } from '../../constants/enum';
import ShareValidationRequestEmail from './ShareValidationRequestEmail';
import StatrysLoader from '../ui/loaders/StatrysLoader';

function RequestValidation({
  openValidationModal,
  closeValidationModal,
  emailInvoiceData,
  getImportingInvoiceDetails,
  validatorsList,
  getListOfValidators,
  companyDetails,
  showAlreadySharedModal,
  sendEmailLoading
}) {
  const [check, setCheck] = useState(null);
  const [selectedValidator, setSelectedValidator] = useState('');
  const [validatorModal, setValidatorModal] = useState(false);
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const handleRequestValidation = () => {
    setValidatorModal(true);
    closeValidationModal(false);
  };

  const handleToCloseValidationModal = () => {
    setCheck();
    setSelectedValidator('');
    setValidatorModal(false);
  };

  const selectValidator = (index, data) => {
    setCheck(index);
    setSelectedValidator(data);
  };

  const handleValidationEmailModal = () => {
    setValidatorModal(true);
    closeValidationModal(false);
  };

  return (
    <div>
      <DynamicModal openDynamicModal={openValidationModal} closeDynamicModal={closeValidationModal} minWidth="520px">
        <div className="mt-5">
          <MDSubtitleText
            className="flex justify-center mb-8"
            title="Ask to validate the invoice"
            fontWeight="text-bold"
          />{' '}
          {sendEmailLoading ? (
            <StatrysLoader />
          ) : (
            <div>
              {showAlreadySharedModal ? (
                <div className="bg-green-200 rounded-lg p-4 mt-8 flex gap-4 items-start">
                  <img src={TimeClockIcon} alt="time-clock" />
                  <div>
                    <XSText fontWeight="text-bold" title="You have already requested validation by email today." />
                    <XSText className="mt-2" title="Wait until tomorrow to request another validation." />
                  </div>
                </div>
              ) : (
                <div>
                  <div className="flex justify-center mb-4 uploaded-imported-invoice-preview">
                    <div className="w-96 h-52 overflow-hidden incoming-image-background">
                      <Document file={emailInvoiceData?.pdfSignedURL}>
                        <div>
                          <Page scale={0.8} pageNumber={1} />
                        </div>
                      </Document>
                    </div>
                    <div className="incoming-invoice-modal-image-gradient"></div>
                  </div>
                  <div className="px-10">
                    <XSText
                      className="mb-4"
                      textColor="text-gray-500"
                      title="Select a team member to validate this invoice"
                    />
                    <div className="incoming-invoice-request-validation">
                      {validatorsList?.map((validator, index) => (
                        <>
                          <div
                            className="flex justify-between mx-6 my-4 cursor-pointer"
                            onClick={() => selectValidator(index, validator)}
                          >
                            <div className="flex items-center" key={index + 1}>
                              <Avatar
                                avatarStyling="w-6 h-6"
                                avatarName={validator?.email && validator?.email?.charAt(0)}
                                fontSize="text-xs"
                              />
                              <XSText className="ml-1" title={validator?.email} />
                            </div>
                            <Radio checked={check === index} size="small" />
                          </div>
                          <Divider />{' '}
                        </>
                      ))}
                      <div className="mx-6 my-6" onClick={handleValidationEmailModal}>
                        <XSText textColor="text-coral-500 cursor-pointer" title="+ Add a new team member" />
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-center flex-row gap-4 mt-8 mb-4">
                    <PrimaryButton
                      id="request-validation-save-btn"
                      disabled={selectedValidator === ''}
                      caption="Save Request"
                      type="small"
                      className="modal-save-button"
                      onClick={handleRequestValidation}
                    />
                    <PrimaryButton
                      id="request-validation-cancel-btn"
                      className="modal-cancel-button font-weight-bold border border-gray-400"
                      bgColor="bg-white"
                      color="text-gray-300"
                      caption="Cancel"
                      onClick={closeValidationModal}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </DynamicModal>
      <ShareValidationRequestEmail
        openValidatioEmailModal={validatorModal}
        closeValidationMailDynamicModal={() => handleToCloseValidationModal()}
        modalTitle="Ask to validate the invoice"
        emailInvoiceData={emailInvoiceData}
        validatorData={selectedValidator}
        companyDetails={companyDetails}
        getImportingInvoiceDetails={getImportingInvoiceDetails}
        getListOfValidators={getListOfValidators}
      />
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    getValidatorList: () => dispatch(importedInvoiceReducer.getRequestValidators())
  };
};

export default connect(null, mapDispatchToProps)(RequestValidation);

RequestValidation.propTypes = {
  emailInvoiceData: PropTypes.func,
  openValidationModal: PropTypes.bool,
  closeValidationModal: PropTypes.any,
  getValidatorList: PropTypes.func,
  getImportingInvoiceDetails: PropTypes.any,
  companyDetails: PropTypes.object
};

import * as actionTypes from './actionTypes';
import { ENDPOINTS } from '../../../service/services';
import Api from '../../../store/Interceptor';
import axios from 'axios';

const STATRYS_BASE_API_URL = process.env.STATRYS_BASE_API_URL;

export const fileUploadRequestStart = () => ({ type: actionTypes.FILE_UPLOAD_REQUEST_START });
export const fileUploadRequestFail = (error) => ({
  type: actionTypes.FILE_UPLOAD_REQUEST_FAILURE,
  error: error
});
export const fileUploadRequestSuccess = (uploadedFile) => ({
  type: actionTypes.FILE_UPLOAD_REQUEST_SUCCESS,
  uploadedFile: uploadedFile
});
export const fileUpload = (fileUploadPayload) => {
  return async (dispatch) => {
    try {
      dispatch(fileUploadRequestStart());
      let fileUploadResponse = await Api.put(ENDPOINTS.UPLOAD, fileUploadPayload);
      dispatch(fileUploadRequestSuccess(fileUploadResponse?.data));
      return fileUploadResponse;
    } catch (error) {
      dispatch(fileUploadRequestFail(error.response));
      return error?.response;
    }
  };
};

export const checkSendEmailRequestStart = () => ({ type: actionTypes.CHECK_SEND_EMAIL_REQUEST_START });
export const checkSendEmailRequestFail = (error) => ({
  type: actionTypes.CHECK_SEND_EMAIL_REQUEST_FAILURE,
  error: error
});
export const checkSendEmailRequestSuccess = (checkSendEmail) => ({
  type: actionTypes.CHECK_SEND_EMAIL_REQUEST_SUCCESS,
  checkSendEmail: checkSendEmail
});
export const checkSendEmail = (checkSendEmailPayload) => {
  return async (dispatch) => {
    try {
      dispatch(checkSendEmailRequestStart());
      let checkSendEmailResponse = await Api.post(ENDPOINTS.CHECK_SEND_EMAIL, { invoiceId: checkSendEmailPayload });
      dispatch(checkSendEmailRequestSuccess(checkSendEmailResponse?.data));
      return checkSendEmailResponse;
    } catch (error) {
      dispatch(checkSendEmailRequestFail(error.response));
      return error?.response;
    }
  };
};
export const checkValidateSendEmailRequestStart = () => ({ type: actionTypes.CHECK_VALIDATE_SEND_EMAIL_REQUEST_START });
export const checkValidateSendEmailRequestFail = (error) => ({
  type: actionTypes.CHECK_VALIDATE_SEND_EMAIL_REQUEST_FAILURE,
  error: error
});
export const checkValidateSendEmailRequestSuccess = (checkValidateSendEmail) => ({
  type: actionTypes.CHECK_VALIDATE_SEND_EMAIL_REQUEST_SUCCESS,
  checkValidateSendEmail: checkValidateSendEmail
});
export const checkValidateSendEmail = (checkValidateSendEmailPayload) => {
  return async (dispatch) => {
    try {
      dispatch(checkValidateSendEmailRequestStart());
      let checkValidateSendEmailResponse = await Api.post(ENDPOINTS.CHECK_VALIDATE_SEND_EMAIL, {
        invoiceId: checkValidateSendEmailPayload
      });
      dispatch(checkValidateSendEmailRequestSuccess(checkValidateSendEmailResponse?.data));
      return checkValidateSendEmailResponse;
    } catch (error) {
      dispatch(checkValidateSendEmailRequestFail(error.response));
      return error?.response;
    }
  };
};

export const getCountryRequestStart = () => ({ type: actionTypes.GET_COUNTRY_REQUEST_START });
export const getCountryRequestFail = (error) => ({
  type: actionTypes.GET_COUNTRY_REQUEST_FAILURE,
  error: error
});
export const getCountryRequestSuccess = (getCountryPayload) => ({
  type: actionTypes.GET_COUNTRY_REQUEST_SUCCESS,
  getCountryPayload: getCountryPayload
});
export const getCountry = (getCountryPayload) => {
  return async (dispatch) => {
    try {
      dispatch(getCountryRequestStart());
      let getCountryResponse = await axios.get(STATRYS_BASE_API_URL, getCountryPayload);
      dispatch(getCountryRequestSuccess(getCountryResponse?.data));
      return getCountryResponse;
    } catch (error) {
      dispatch(getCountryRequestFail(error.response));
      return error?.response;
    }
  };
};

export const previewDocumentStart = () => ({ type: actionTypes.PREVIEW_DOCUMENT_REQUEST_START });
export const previewDocumentFail = (error) => ({
  type: actionTypes.PREVIEW_DOCUMENT_REQUEST_FAILURE,
  error: error
});
export const previewDocumentSuccess = (previewedDocument) => ({
  type: actionTypes.PREVIEW_DOCUMENT_REQUEST_SUCCESS,
  previewedDocument: previewedDocument
});
export const previewDocument = (previewedDocument) => {
  return async (dispatch) => {
    try {
      dispatch(previewDocumentStart());
      let previewDocumentResponse = await Api.post(ENDPOINTS.PREVIEW_DOCUMENT, previewedDocument);
      dispatch(previewDocumentSuccess(previewDocumentResponse?.data));
      return previewDocumentResponse?.data;
    } catch (error) {
      dispatch(previewDocumentFail(error.response));
      return error?.response;
    }
  };
};

export const getPdfSignedUrlStart = () => ({ type: actionTypes.PDF_SIGNED_URL_REQUEST_START });
export const getPdfSignedUrlFail = (error) => ({
  type: actionTypes.PDF_SIGNED_URL_REQUEST_FAILURE,
  error: error
});
export const getPdfSignedUrlSuccess = (pdfSignedUrl) => ({
  type: actionTypes.PDF_SIGNED_URL_REQUEST_SUCCESS,
  pdfSignedUrl: pdfSignedUrl
});
export const getPdfSignedUrl = (pdfSignedUrl) => {
  return async (dispatch) => {
    try {
      dispatch(getPdfSignedUrlStart());
      let getPdfSignedUrlResponse = await Api.post(ENDPOINTS.SIGNED_PDF_URL, pdfSignedUrl);
      dispatch(getPdfSignedUrlSuccess(getPdfSignedUrlResponse?.data));
      return getPdfSignedUrlResponse?.data;
    } catch (error) {
      dispatch(getPdfSignedUrlFail(error.response));
      return error?.response;
    }
  };
};

export const uploadDocumentStart = () => ({ type: actionTypes.UPLOAD_DOCUMENT_REQUEST_START });
export const uploadDocumentFail = (error) => ({
  type: actionTypes.UPLOAD_DOCUMENT_REQUEST_FAILURE,
  error: error
});
export const uploadDocumentSuccess = (uploadedDocument) => ({
  type: actionTypes.UPLOAD_DOCUMENT_REQUEST_SUCCESS,
  uploadedDocument: uploadedDocument
});
export const uploadDocument = (uploadedDocument) => {
  return async (dispatch) => {
    try {
      dispatch(uploadDocumentStart());
      let uploadDocumentResponse = await Api.put(ENDPOINTS.UPLOAD_DOCUMENT, uploadedDocument);
      dispatch(uploadDocumentSuccess(uploadDocumentResponse?.data));
      return uploadDocumentResponse;
    } catch (error) {
      dispatch(uploadDocumentFail(error.response));
      return error?.response;
    }
  };
};

export const publicDocumentUploadRequestStart = () => ({ type: actionTypes.PUBLIC_DOCUMENT_UPLOAD_REQUEST_START });
export const publicDocumentUploadRequestFail = (error) => ({
  type: actionTypes.PUBLIC_DOCUMENT_UPLOAD_REQUEST_FAILURE,
  error: error
});
export const publicDocumentUploadRequestSuccess = (uploadedFile) => ({
  type: actionTypes.PUBLIC_DOCUMENT_UPLOAD_REQUEST_SUCCESS,
  uploadedFile: uploadedFile
});
export const publicDocumentUpload = (publicDocumentUploadPayload) => {
  return async (dispatch) => {
    try {
      dispatch(publicDocumentUploadRequestStart());
      let publicDocumentUploadResponse = await Api.put(ENDPOINTS.PUBLIC_DOCUMENT_UPLOAD, publicDocumentUploadPayload);
      dispatch(publicDocumentUploadRequestSuccess(publicDocumentUploadResponse?.data));
      return publicDocumentUploadResponse;
    } catch (error) {
      dispatch(publicDocumentUploadRequestFail(error.response));
      return error?.response;
    }
  };
};

import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby'; // @mui
import {
  Box,
  Tab,
  Tabs,
  Card,
  Table,
  Stack,
  Tooltip,
  Divider,
  TableBody,
  IconButton,
  TableContainer,
  TablePagination
} from '@mui/material';
// routes
import { PATH_PAGE } from '../routes/paths';
// hooks
import useTabs from '../hooks/useTabs';
import useTable, { getComparator, emptyRows } from '../hooks/useTable';
// _mock_
import {
  API_CODE,
  INVOICE_STATUS,
  MUI_COLORS,
  PAGE_TITLE,
  ROW_PER_PAGE_OPTIONS,
  STATUS_COLORS,
  TYPE_OF_INVOICE
} from '../constants/enum';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Iconify from '../components/Iconify';
import Scrollbar from '../components/Scrollbar';
import HeaderBreadcrumbs from '../components/HeaderBreadcrumbs';
import { TableNoData, TableEmptyRows, TableHeadCustom, TableSelectedActions } from '../components/table';
import Layout from '../components/layout';
// sections
import { InvoiceTableRow, InvoiceTableToolbar } from '../sections/@dashboard/invoice/list';
import InvoiceCurrenciesList from '../data/invoice-currencies-list.json';
import GetStartedBanner from '../components/common/GetStartedBanner';
import PrimaryButton from '../components/ui/buttons/PrimaryButton';
import IconPlus from '../images/icons/plus-icon.svg';
import MSText from '../components/ui/typography/MSText';
import { getTimeDate } from '../utils/formatTime';
import XSText from '../components/ui/typography/XSText';
import Snackbar from '../components/Snackbar';
import Image from '../components/Image';
import Success from '../images/icons/success-delete-icon.svg';
import PropTypes from 'prop-types';
import * as invoiceActions from '../components/invoice/reduxStore/action';
import { connect } from 'react-redux';
import TableStatrysLoader from '../components/table/TableStatrysLoader';
import StatrysLoader from '../components/ui/loaders/StatrysLoader';
import * as profileActions from '../components/profile/reduxStore/action';
import { addCommas, findTotalCharacter } from '../helper/helpers';
import AnalyticsGetpaid from '../sections/@dashboard/invoice/AnalyticsGetpaid';
import SettingToggleIcon from '../images/icons/Invoice/gear-icon.svg';
import PaymentReview from '../components/paymentReview/PaymentReview';
import Seo from '../components/seo';

// ----------------------------------------------------------------------

const SERVICE_OPTIONS = [
  'All',
  'full stack development',
  'backend development',
  'ui design',
  'ui/ux design',
  'front end development'
];

const TABLE_HEAD = [
  { id: 'invoiceNumber', label: 'Client', align: 'left' },
  { id: 'createdAt', label: 'Create', align: 'left' },
  { id: 'dueDate', label: 'Due', align: 'left' },
  { id: 'totalAmount', label: 'Amount', align: 'left', width: 200 },
  { id: 'status', label: 'Status', align: 'left' },
  { id: '' }
];

// ----------------------------------------------------------------------

function InvoiceList({
  getInvoiceList,
  deleteInvoice,
  getInvoiceDetails,
  createDuplicateInvoice,
  invoiceCurrency,
  invoiceCount,
  getCurrencyPreference
}) {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangePage,
    onChangeRowsPerPage
  } = useTable({ defaultOrderBy: 'dateCreated' });
  const [isLoading, setIsLoading] = useState(true);

  const [tableData, setTableData] = useState([]);

  const [filterName, setFilterName] = useState('');
  const [currency, setCurrency] = useState(InvoiceCurrenciesList[0]);

  const [filterService, setFilterService] = useState(INVOICE_STATUS.INVOICE_TOTAL);

  const [filterStartDate, setFilterStartDate] = useState(null);

  const [filterEndDate, setFilterEndDate] = useState(null);
  const [totalAmountCurrency, setTotalAmountCurrency] = useState('');

  //Snackbar
  const [open, setOpen] = useState(false);

  const [successMessage, setSuccessMessage] = useState({
    title: '',
    content: ''
  });

  const getPreferedCurrency = async () => {
    const getCurrencyDataResponse = await getCurrencyPreference();
    if (getCurrencyDataResponse?.status === API_CODE.STATUS_200) {
      setCurrency(getCurrencyDataResponse?.data?.data?.currency);
    }
  };

  const handleAnalyticsTabs = () => {
    navigate(PATH_PAGE.profile, {
      state: { preferences: true }
    });
  };

  useEffect(() => {
    handleInvoiceList();
    getPreferedCurrency();
  }, []);
  const handleInvoiceList = async () => {
    setIsLoading(true);
    const getInvoiceListResponse = await getInvoiceList();
    setTableData(getInvoiceListResponse?.data?.data?.invoiceData);
    totalInvoiceAmount();
    setIsLoading(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { currentTab: filterStatus, onChangeTab: onFilterStatus } = useTabs(INVOICE_STATUS.INVOICE_TOTAL);
  const handleFilterName = (filterName) => {
    setFilterName(filterName);
    setPage(0);
  };

  const handleFilterService = (event) => {
    setFilterService(event.target.value);
  };

  const invoiceCountHandler = async (invoiceData) => {
    const payload = {
      contactId: invoiceData?.contact?.contactId
    };
    const invoiceCountResponse = await invoiceCount(payload);
    if (invoiceCountResponse?.status === API_CODE.STATUS_200) {
      createDuplicateInvoiceHandler(invoiceData, invoiceCountResponse?.data?.data);
    }
  };

  const handleDuplicateRow = async (id) => {
    const getInvoiceResponse = await getInvoiceDetails(id);
    if (getInvoiceResponse?.status === API_CODE.STATUS_200) {
      invoiceCountHandler(getInvoiceResponse?.data?.data);
    }
  };

  const createDuplicateInvoiceHandler = async (invoiceResponse, invoiceCount) => {
    const requestPayload = {
      companyDetails: invoiceResponse?.companyDetails,
      contact: invoiceResponse?.contact,
      services: invoiceResponse?.services,
      goods: invoiceResponse?.goods,
      items: invoiceResponse?.items,
      taxWithPrice: invoiceResponse?.taxWithPrice,
      invoiceDiscount: invoiceResponse?.invoiceDiscount,
      currency: invoiceResponse?.currency,

      notes: invoiceResponse?.notes,
      invoiceNumber: `INV-${invoiceCount}`,
      dueDate: invoiceResponse?.dueDate ? invoiceResponse?.dueDate : '',
      createDate: invoiceResponse?.createDate,
      otherReferences: invoiceResponse?.otherReferences,
      totalAmount: invoiceResponse?.totalAmount,
      subtotal: invoiceResponse?.subtotal,
      logoPublic: invoiceResponse?.logoPublic,
      paymentDetails: invoiceResponse?.paymentDetails,
      status: INVOICE_STATUS.INVOICE_DRAFT,
      userId: invoiceResponse?.userId
    };
    const getDuplicateInvoice = await createDuplicateInvoice(requestPayload);
    if (getDuplicateInvoice?.status === API_CODE.STATUS_200) {
      handleInvoiceList();
      navigate(PATH_PAGE.editInvoice + `?id=${getDuplicateInvoice?.data?.data?._id}`);
    }
  };

  const handleDeleteRow = async (id, invoiceNumber) => {
    const deleteInvoiceResponse = await deleteInvoice(id);
    if (deleteInvoiceResponse?.status === API_CODE.STATUS_200) {
      const deleteRow = tableData.filter((row) => row?._id !== id);
      setSelected([]);
      setTableData(deleteRow);
      setSuccessMessage({
        ...successMessage,
        title: deleteInvoiceResponse?.data?.message,
        content: `Invoice ${invoiceNumber} was successfully deleted!`
      });
      setOpen(true);
    }
    totalInvoiceAmount();
  };
  const handleDeleteRows = (selected) => {
    const deleteRows = tableData.filter((row) => !selected.includes(row?._id));
    setSelected([]);
    setTableData(deleteRows);
  };

  const handleEditRow = (id) => {
    navigate(PATH_PAGE.editInvoice + `?id=${id}`);
  };

  const handleViewRow = (id) => {
    navigate(PATH_PAGE.viewInvoice + `?id=${id}`);
  };

  const onClickSelectedRowHandler = (id, status) => {
    if (status === INVOICE_STATUS.INVOICE_DRAFT) {
      navigate(PATH_PAGE.editInvoice + `?id=${id}`);
    } else {
      navigate(PATH_PAGE.viewInvoice + `?id=${id}`);
    }
  };
  const totalInvoiceAmount = async () => {
    const payload = {
      dashboardType: TYPE_OF_INVOICE.INVOICE
    };
    const invoiceTotalAmountResponse = await invoiceCurrency({ params: { ...payload } });
    if (invoiceTotalAmountResponse?.status === API_CODE.STATUS_200) {
      setTotalAmountCurrency(invoiceTotalAmountResponse?.data?.data);
    }
  };

  const dataFiltered = applySortFilter({
    tableData,
    comparator: getComparator(order, orderBy),
    filterName,
    filterService,
    filterStatus,
    filterStartDate,
    filterEndDate
  });

  const isNotFound =
    (!dataFiltered?.length && !!filterName) ||
    (!dataFiltered?.length && !!filterStatus) ||
    (!dataFiltered?.length && !!filterService) ||
    (!dataFiltered?.length && !!filterEndDate) ||
    (!dataFiltered?.length && !!filterStartDate);

  const getLengthByStatus = (status) => tableData?.filter((item) => item.status === status).length;

  const getPercentByStatus = (status) => (getLengthByStatus(status) / tableData?.length) * 100;

  const TABS = [
    {
      value: INVOICE_STATUS.INVOICE_TOTAL,
      label: INVOICE_STATUS.INVOICE_TOTAL,
      color: 'error',
      count: tableData?.length
    },
    {
      value: INVOICE_STATUS.INVOICE_PAID,
      label: INVOICE_STATUS.INVOICE_PAID,
      color: 'success',
      count: getLengthByStatus(INVOICE_STATUS.INVOICE_PAID),
      variant: 'filled'
    },
    {
      value: INVOICE_STATUS.INVOICE_UNPAID,
      label: INVOICE_STATUS.INVOICE_UNPAID,
      color: 'warning',
      count: getLengthByStatus(INVOICE_STATUS.INVOICE_UNPAID),
      variant: 'filled'
    },
    {
      value: INVOICE_STATUS.INVOICE_REVIEW,
      label: INVOICE_STATUS.INVOICE_REVIEW,
      color: 'warning',
      count: getLengthByStatus(INVOICE_STATUS.INVOICE_REVIEW),
      variant: 'filled'
    },
    {
      value: INVOICE_STATUS.INVOICE_OVERDUE,
      label: INVOICE_STATUS.INVOICE_OVERDUE,
      color: 'error',
      count: getLengthByStatus(INVOICE_STATUS.INVOICE_OVERDUE),
      variant: 'filled'
    },
    {
      value: INVOICE_STATUS.INVOICE_DRAFT,
      label: INVOICE_STATUS.INVOICE_DRAFT,
      color: 'default',
      count: getLengthByStatus(INVOICE_STATUS.INVOICE_DRAFT),
      variant: 'filled'
    }
  ];

  const addedUnpaidAndReview = Number(totalAmountCurrency?.Unpaid) + Number(totalAmountCurrency?.Review);

  const handleTabChange = (e, value) => {
    if (value !== INVOICE_STATUS.INVOICE_TOTAL) {
      onChangePage(e, 0);
    }
    onFilterStatus(e, value);
  };

  return (
    <Layout>
      <Seo
        title="Get Paid - Invoicing | Statrys"
        description="Create, edit and send your invoices and get paid faster with Statrys."
      />
      <Page title="Invoice: List">
        <HeaderBreadcrumbs
          heading={PAGE_TITLE.INVOICE_LIST}
          links={[{ name: '' }]}
          action={
            <>
              <PrimaryButton
                id="getpaid-create-invoice-btn"
                urlLink={PATH_PAGE.createInvoice}
                caption={
                  <div className="flex gap-1 items-center">
                    <img src={IconPlus} alt={IconPlus} />
                    <MSText textColor="text-white" fontWeight="text-bold" title="Create Invoice" />
                  </div>
                }
                type="small"
              />
            </>
          }
        />

        <Scrollbar>
          <Stack
            direction="row"
            sx={{
              py: 2
            }}
          >
            <AnalyticsGetpaid
              title="Paid"
              analyticsClass="w-2/12"
              total={getLengthByStatus(INVOICE_STATUS.INVOICE_PAID)}
              percent={getPercentByStatus(INVOICE_STATUS.INVOICE_PAID)}
              currency={currency}
              isAmountHuge={findTotalCharacter(currency, totalAmountCurrency?.TotalAmount?.toFixed(2))}
              price={addCommas(totalAmountCurrency?.Paid?.toFixed(2))}
              rootClass="bg-green-500 mr-2 "
              titleClass="text-green-200"
              amountColor="text-white"
              totalClass="text-white"
              invoiceClass="text-green-200"
            />
            <AnalyticsGetpaid
              analyticsClass="w-2/12"
              title="Overdue"
              total={getLengthByStatus(INVOICE_STATUS.INVOICE_OVERDUE)}
              percent={getPercentByStatus(INVOICE_STATUS.INVOICE_OVERDUE)}
              currency={currency}
              isAmountHuge={findTotalCharacter(currency, totalAmountCurrency?.TotalAmount?.toFixed(2))}
              price={addCommas(totalAmountCurrency?.Overdue?.toFixed(2))}
              rootClass="bg-salmon-500 mr-2"
              titleClass="text-red-alert"
              totalClass="text-coral-200"
              invoiceClass="text-coral-200"
            />
            <AnalyticsGetpaid
              analyticsClass="w-2/12"
              title="Unpaid"
              total={
                getLengthByStatus(INVOICE_STATUS.INVOICE_UNPAID) + getLengthByStatus(INVOICE_STATUS.INVOICE_REVIEW)
              }
              percent={getPercentByStatus(INVOICE_STATUS.INVOICE_UNPAID)}
              currency={currency}
              isAmountHuge={findTotalCharacter(currency, totalAmountCurrency?.TotalAmount?.toFixed(2))}
              price={addedUnpaidAndReview >= 0 && addCommas(addedUnpaidAndReview?.toFixed(2))}
              rootClass="bg-yellow-500 mr-2"
              titleClass="text-blue-100"
              totalClass="text-blue-100"
              invoiceClass="text-gray-700"
            />

            <AnalyticsGetpaid
              analyticsClass="w-2/12"
              title="Draft"
              total={getLengthByStatus(INVOICE_STATUS.INVOICE_DRAFT)}
              percent={getPercentByStatus(INVOICE_STATUS.INVOICE_DRAFT)}
              currency={currency}
              isAmountHuge={findTotalCharacter(currency, totalAmountCurrency?.TotalAmount?.toFixed(2))}
              price={addCommas(totalAmountCurrency?.Draft?.toFixed(2))}
              rootClass="bg-gray-400 mr-2"
              titleClass="text-gray-800"
              totalClass="text-blue-100"
              invoiceClass="text-gray-700"
            />
            <AnalyticsGetpaid
              analyticsClass="w-4/12"
              title="Total"
              total={tableData?.length}
              currency={currency}
              isAmountHuge={findTotalCharacter(currency, totalAmountCurrency?.TotalAmount?.toFixed(2))}
              price={addCommas(totalAmountCurrency?.TotalAmount?.toFixed(2))}
              rootClass="bg-white"
              titleClass="text-blue-100"
              amountColor="text-blue-100"
              totalClass="text-blue-100"
              invoiceClass="text-gray-700"
              icon={SettingToggleIcon}
              handleAnalyticsTabs={handleAnalyticsTabs}
            />
          </Stack>
        </Scrollbar>
        <PaymentReview invoiceList={handleInvoiceList} isInvoiceListUpdated={tableData} />
        {isLoading ? (
          <StatrysLoader />
        ) : tableData?.length > 0 ? (
          <>
            <Card>
              <Tabs
                allowScrollButtonsMobile
                variant="scrollable"
                scrollButtons="auto"
                value={filterStatus}
                onChange={handleTabChange}
                sx={{ px: 2, bgcolor: 'background.neutral' }}
              >
                {TABS?.map((tab) => (
                  <Tab
                    disableRipple
                    key={tab?.value}
                    value={tab?.value}
                    icon={
                      <Label color={tab?.color} variant={tab?.variant}>
                        {' '}
                        {tab?.count}{' '}
                      </Label>
                    }
                    label={tab?.label}
                  />
                ))}
              </Tabs>

              <Divider />

              <InvoiceTableToolbar
                filterName={filterName}
                filterService={filterService}
                filterStartDate={filterStartDate}
                filterEndDate={filterEndDate}
                onFilterName={handleFilterName}
                onFilterService={handleFilterService}
                onFilterStartDate={(newValue) => {
                  setFilterStartDate(newValue);
                }}
                onFilterEndDate={(newValue) => {
                  setFilterEndDate(newValue);
                }}
                optionsService={SERVICE_OPTIONS}
              />
              <Scrollbar>
                <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
                  {selected.length > 0 && (
                    <TableSelectedActions
                      dense={dense}
                      numSelected={selected.length}
                      rowCount={tableData?.length}
                      onSelectAllRows={(checked) =>
                        onSelectAllRows(
                          checked,
                          tableData?.map((row) => row?._id)
                        )
                      }
                      actions={
                        <Stack spacing={1} direction="row">
                          <Tooltip title="Sent">
                            <IconButton color="primary">
                              <Iconify icon={'ic:round-send'} />
                            </IconButton>
                          </Tooltip>

                          <Tooltip title="Download">
                            <IconButton color="primary">
                              <Iconify icon={'eva:download-outline'} />
                            </IconButton>
                          </Tooltip>

                          <Tooltip title="Print">
                            <IconButton color="primary">
                              <Iconify icon={'eva:printer-fill'} />
                            </IconButton>
                          </Tooltip>

                          <Tooltip title="Delete">
                            <IconButton color="primary" onClick={() => handleDeleteRows(selected)}>
                              <Iconify icon={'eva:trash-2-outline'} />
                            </IconButton>
                          </Tooltip>
                        </Stack>
                      }
                    />
                  )}

                  <Table>
                    <TableHeadCustom
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={tableData?.length}
                      numSelected={selected.length}
                      onSort={onSort}
                      onSelectAllRows={(checked) =>
                        onSelectAllRows(
                          checked,
                          tableData?.map((row) => row?._id)
                        )
                      }
                    />
                    <TableBody>
                      {isLoading ? (
                        <TableStatrysLoader />
                      ) : (
                        dataFiltered
                          ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((row) => (
                            <InvoiceTableRow
                              key={row?._id}
                              row={row}
                              selected={selected.includes(row?._id)}
                              onClickSelectedRow={() => onClickSelectedRowHandler(row?._id, row?.status)}
                              onSelectRow={() => onSelectRow(row?._id)}
                              onViewRow={() => handleViewRow(row?._id)}
                              onEditRow={() => handleEditRow(row?._id)}
                              onDeleteRow={() => handleDeleteRow(row?._id, row?.invoiceNumber)}
                              onDuplicateRow={() => handleDuplicateRow(row?._id)}
                            />
                          ))
                      )}

                      <TableEmptyRows emptyRows={emptyRows(page, rowsPerPage, tableData?.length)} />

                      <TableNoData isNotFound={isNotFound} />
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>

              <Box sx={{ position: 'relative' }}>
                <TablePagination
                  rowsPerPageOptions={[
                    ROW_PER_PAGE_OPTIONS.TEN,
                    ROW_PER_PAGE_OPTIONS.TWENTY_FIVE,
                    ROW_PER_PAGE_OPTIONS.FIFTY
                  ]}
                  component="div"
                  count={dataFiltered?.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={onChangePage}
                  onRowsPerPageChange={onChangeRowsPerPage}
                />
              </Box>
            </Card>
          </>
        ) : (
          <GetStartedBanner />
        )}
        <Snackbar
          open={open}
          handleClose={handleClose}
          sx={{ bgcolor: MUI_COLORS.LIGHT_GREEN, border: '1px solid', borderColor: MUI_COLORS.GREEN }}
          title={
            <div className="flex">
              <div className="flex items-center">
                <Image className="w-5 h-5" src={Success} />
              </div>
              <div className="ml-4">
                <XSText fontWeight="text-bold" textColor="text-gray-500" title={successMessage.title}></XSText>
                <MSText textColor="text-gray-500" title={successMessage.content}></MSText>
              </div>
            </div>
          }
        ></Snackbar>
      </Page>
    </Layout>
  );
}

// ----------------------------------------------------------------------

function applySortFilter({
  tableData,
  comparator,
  filterName,
  filterStatus,
  filterService,
  filterStartDate,
  filterEndDate
}) {
  const stabilizedThis = tableData?.map((el, index) => [el, index]);

  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  tableData = stabilizedThis?.map((el) => el[0]);

  if (filterName) {
    tableData = tableData?.filter(
      (item) =>
        item.invoiceNumber.toLowerCase().indexOf(filterName.toLowerCase()) !== -1 ||
        item.contact?.companyName.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
    );
  }

  if (filterStatus !== INVOICE_STATUS.INVOICE_TOTAL) {
    tableData = tableData?.filter((item) => item.status === filterStatus);
  }

  if (filterStartDate && filterEndDate) {
    tableData = tableData?.filter(
      (item) =>
        getTimeDate(item.createdAt) >= filterStartDate.getTime() && getTimeDate(item.dueDate) <= filterEndDate.getTime()
    );
  }

  return tableData;
}

const mapStateToProps = (state) => {
  return {
    getInvoiceDetails: state?.invoiceDetails?.invoiceData
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getInvoiceList: () => dispatch(invoiceActions.getInvoiceList()),
    deleteInvoice: (deleteInvoiceId) => dispatch(invoiceActions.deleteInvoice(deleteInvoiceId)),
    getInvoiceDetails: (deleteInvoiceId) => dispatch(invoiceActions.getInvoiceDetails(deleteInvoiceId)),
    createDuplicateInvoice: (deleteInvoiceData) => dispatch(invoiceActions.createInvoice(deleteInvoiceData)),
    invoiceCount: (invoiceId) => dispatch(invoiceActions.invoiceCount(invoiceId)),
    invoiceCurrency: (invoiceData) => dispatch(invoiceActions.invoiceCurrency(invoiceData)),
    getCurrencyPreference: (payload) => dispatch(profileActions.getCurrencyPreference(payload))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceList);

InvoiceList.propTypes = {
  getInvoiceDetails: PropTypes.object,
  getInvoiceList: PropTypes.func,
  deleteInvoice: PropTypes.func,
  getInvoiceDetails: PropTypes.func,
  createDuplicateInvoice: PropTypes.func,
  invoiceCount: PropTypes.func,
  invoiceCurrency: PropTypes.func,
  getCurrencyPreference: PropTypes.func
};

import React, { useEffect, useState } from 'react';
import Layout from '../../components/layout';
import Seo from '../../components/seo';
import InvoiceDetails from '../../components/invoice/InvoiceDetails';
import * as userActions from '../../components/invoice/reduxStore/action';
import { useLocation } from '@reach/router';
import { API_CODE } from '../../constants/enum';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const EditInvoice = ({ getInvoiceDataById, resetInvoiceDetails, invoiceData }) => {
  const [isEditDataStored, setIsEditDataStored] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [error, setError] = useState(false);

  const location = useLocation();
  const params = new URLSearchParams(location?.search);
  const id = params.get('id');

  useEffect(() => {
    getInvoiceDetail(id);
    return () => {
      resetInvoiceDetails();
    };
  }, []);

  const getInvoiceDetail = async (id) => {
    const invoiceByIdResponse = await getInvoiceDataById(id);
    if (invoiceByIdResponse?.status === API_CODE?.STATUS_200) {
      const invoiceDataById = invoiceByIdResponse?.data?.data;
      await invoiceData({
        fromFirstName: invoiceDataById?.companyDetails?.firstName,
        fromLastName: invoiceDataById?.companyDetails?.lastName,
        fromEmail: invoiceDataById?.companyDetails?.email,
        fromCompanyName: invoiceDataById?.companyDetails?.companyName,
        fromRegistrationNumber: invoiceDataById?.companyDetails?.registrationNumber,
        fromWebsite: invoiceDataById?.companyDetails?.website,
        fromAddress: invoiceDataById?.companyDetails?.address,
        fromTownCity: invoiceDataById?.companyDetails?.city,
        fromState: invoiceDataById?.companyDetails?.state,
        fromPostalCode: invoiceDataById?.companyDetails?.postalCode,
        fromCountry: invoiceDataById?.companyDetails?.country,

        toFirstName: invoiceDataById?.contact?.firstName,
        toLastName: invoiceDataById?.contact?.lastName,
        toEmail: invoiceDataById?.contact?.email,
        toCompanyName: invoiceDataById?.contact?.companyName,
        toRegistrationNumber: invoiceDataById?.contact?.registrationNumber,
        toWebsite: invoiceDataById?.contact?.website,
        toAddress: invoiceDataById?.contact?.address,
        toTownCity: invoiceDataById?.contact?.city,
        toState: invoiceDataById?.contact?.state,
        toPostalCode: invoiceDataById?.contact?.postalCode,
        toCountry: invoiceDataById?.contact?.country,
        toProfile: invoiceDataById?.contact?.profileImagePublic,
        toId: invoiceDataById?.contact?.contactId,

        invoiceTasks: invoiceDataById?.services,
        invoiceItems: invoiceDataById?.goods,

        invoiceOtherReferences: invoiceDataById?.otherReferences,
        invoiceNumber: invoiceDataById?.invoiceNumber,
        invoiceDateCreated: invoiceDataById?.createDate,
        invoiceDueDate: invoiceDataById?.dueDate,
        paymentDetails: invoiceDataById?.paymentDetails,
        invoiceNotes: invoiceDataById?.notes,
        invoiceCurrency: invoiceDataById?.currency,

        invoiceDiscount: invoiceDataById?.invoiceDiscount,
        invoiceTaxes: invoiceDataById?.taxWithPrice,
        totalAmount: String(invoiceDataById?.totalAmount),
        subtotal: String(invoiceDataById?.subtotal),
        invoiceImage: { preview: invoiceDataById?.logoPublic },
        invoiceStatus: invoiceDataById?.status,
        ...invoiceDataById
      });
      setIsEdit(true);
      setIsEditDataStored(true);
    } else {
      setError(true);
    }
  };
  return (
    <Layout>
      <InvoiceDetails isEdit={isEdit} isEditDataStored={isEditDataStored} getInvoiceDetailById={getInvoiceDetail} />
    </Layout>
  );
};

export const Head = () => <Seo title="Edit Invoice" />;

const mapStateToProps = (state) => {
  return {
    getInvoiceDetails: state?.invoiceDetails?.invoiceData
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    invoiceData: (invoiceData) => dispatch(userActions.invoiceData(invoiceData)),
    resetInvoiceDetails: () => dispatch(userActions.resetInvoiceDetails()),
    getInvoiceDataById: (invoiceData) => dispatch(userActions.getInvoiceDetails(invoiceData))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditInvoice);

EditInvoice.propTypes = {
  getInvoiceDetails: PropTypes.object,
  invoiceData: PropTypes.func,
  resetInvoiceDetails: PropTypes.func,
  type: PropTypes.string,
  getInvoiceDataById: PropTypes.func
};

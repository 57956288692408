import React, { useState, useEffect } from 'react';
import InvoiceIcon from '../../images/graphics/invoice.png';
import { useLocation } from '@reach/router';
import { FILE_EXTENTION_TYPE } from '../../constants/enum';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import * as commonAction from '../commonReduxStore/reduxStore/action';
import { connect } from 'react-redux';
import StatrysLoader from '../ui/loaders/StatrysLoader';
import { getFileExtension } from '../../helper/helpers';
function ImportedInvoiceFileUpload({ pdfURL, previewDocument }) {
  const location = useLocation();
  const [imageUrl, setImageUrl] = useState('');
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function handlePageShifting(index) {
    setPageNumber(index + 1);
  }

  const handlePreviewDocument = async (documentCode) => {
    setIsLoading(true);
    const previewDocumentResponse = await await previewDocument({ code: documentCode, newVersion: true });
    setImageUrl(previewDocumentResponse?.data);
    setIsLoading(false);
  };
  useEffect(() => {
    const documentCode = location?.state?.documentCode;
    if (location?.state?.documentCode) {
      handlePreviewDocument(documentCode);
      setImageUrl();
    } else {
      setImageUrl(pdfURL);
    }
  }, []);

  return (
    <>
      {isLoading ? (
        <StatrysLoader />
      ) : (
        <>
          {getFileExtension(imageUrl) === FILE_EXTENTION_TYPE.PNG ||
          getFileExtension(imageUrl) === FILE_EXTENTION_TYPE.JPEG ||
          getFileExtension(imageUrl) === FILE_EXTENTION_TYPE.JPG ? (
            <img
              className="imported-invoice-upload-container"
              src={imageUrl ? imageUrl : pdfURL}
              alt="Uploaded Image"
            />
          ) : (
            <div>
              <Document file={imageUrl} onLoadSuccess={onDocumentLoadSuccess}>
                <div className="importing-invoice-pdf-preview">
                  <Page pageNumber={pageNumber} />
                </div>
                {pageNumber > 0 && (
                  <div className="importing-invoice-multi-pages flex mt-6 ">
                    {Array.from(new Array(numPages), (el, index) => (
                      <>
                        {numPages > 1 && (
                          <div className={`${index + 1 === pageNumber && 'active-pdf-tab'} mr-4`}>
                            <Page
                              className={`other-pdf-container cursor-pointer`}
                              key={`page_${index + 1}`}
                              pageNumber={index + 1}
                              width={70}
                              height={100}
                              onClick={() => handlePageShifting(index)}
                            />
                          </div>
                        )}
                      </>
                    ))}
                  </div>
                )}
              </Document>
            </div>
          )}
        </>
      )}
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  previewDocument: (documentCode) => dispatch(commonAction.previewDocument(documentCode))
});

export default connect(null, mapDispatchToProps)(ImportedInvoiceFileUpload);

import React, { useState } from 'react';
import MDSubtitleText from '../../ui/typography/MDSubtitleText';
import SelectInputAutoComplete from '../../ui/inputs/selectInputAutoComplete';
import ValidationMessage from '../../ui/ValidationMessage';
import IndustriesList from '../../../data/industries-list.json';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Controller } from 'react-hook-form';
import * as accountSettingActions from '../../accountSetting/reduxStore/action';

function BusinessOfCompany({ register, control, setValue, errors, data, clearErrors }) {
  const setIndustry = (value) => {
    setValue('industries', value ? [value] : value);
    value && clearErrors('industries');
  };

  return (
    <div className="modal-step-container flex flex-col justify-center">
      <MDSubtitleText
        className="md:whitespace-pre-line mb-6"
        fontWeight="text-bold"
        title={`What is the business of \nyour company?`}
      />
      <Controller
        name="industries"
        control={control}
        rules={{ required: true }}
        defaultValue={data?.industries?.length < 1 ? '' : data?.industries}
        render={({ field }) => (
          <SelectInputAutoComplete
            {...field}
            defaultValue={data?.industries?.length < 1 ? '' : data?.industries}
            label="Industry"
            name="industries"
            options={IndustriesList}
            setSelectedValue={setIndustry}
            error={errors?.industries}
            inputRef={register({
              required: true
            })}
            helperText={errors?.industries && <ValidationMessage title="Please select a valid Industry" />}
          />
        )}
      />
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    createCompanyDetails: (companyDetailsPayload) =>
      dispatch(accountSettingActions.createCompanyDetails(companyDetailsPayload))
  };
};

export default connect(null, mapDispatchToProps)(BusinessOfCompany);

BusinessOfCompany.propTypes = {
  createCompanyDetails: PropTypes.func
};

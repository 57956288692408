import React, { useEffect, useState } from 'react';
import H3HeaderText from '../ui/typography/H3HeaderText';
import MDSubtitleText from '../ui/typography/MDSubtitleText';
import XSText from '../ui/typography/XSText';
import PrimaryButton from '../ui/buttons/PrimaryButton';
import AddPaymentModal from './AddPaymentModal';
import ShareInvoiceByMail from '../common/ShareInvoiceByMail';
import XMSText from '../ui/typography/XMSText';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DynamicModal from '../../components/ui/modal/DynamicModal';
import { fDate } from '../../utils/formatTime';
import * as commonActions from '../commonReduxStore/reduxStore/action';
import TimeClockIcon from '../../images/icons/time-clock.svg';
import {
  concatenateSelectedCurrency,
  convertDateToDDMMYYYY,
  getFileExtension,
  isBrowser,
  remainingAmount,
  stringTruncate,
  takeOutImageName
} from '../../helper/helpers';
import RequestValidation from './RequestValidation';
import * as importedInvoiceReducer from '../importedInvoices/reduxStore/action';
import {
  API_CODE,
  FILE_EXTENTION_TYPE,
  INVOICE_STATUS,
  MUI_COLORS,
  PAYMENT_STATUS_INVOICE,
  SOCIAL_SHARE_LINK
} from '../../constants/enum';
import MArkAsPaidMenu from '../IncomingInvoices/MarkAsPaidMenu';
import CopyClipboard from '../../images/icons/customSvg/CopyClipboard';
import MSText from '../ui/typography/MSText';
import * as incomingInvoiceReducer from './reduxStore/action';
import Snackbar from '../Snackbar';
import Image from '../Image';
import SuccessIcon from '../../images/icons/success-icon.svg';
import copy from 'copy-to-clipboard';
import { useLocation } from '@reach/router';
import ShareValidationRequestEmail from './ShareValidationRequestEmail';
import * as accountSettingActions from '../../components/accountSetting/reduxStore/action';
import ShareInvoiceByEmail from './ShareInvoiceByEmail';
import * as importingInvoiceReducer from '../importedInvoices/reduxStore/action';
import Line from '../common/Line';
import Attachment from '../../images/icons/attachment.svg';
import { Document, Page, pdfjs } from 'react-pdf';
import StatrysLoader from '../ui/loaders/StatrysLoader';
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Telegram } from '@mui/icons-material';
import BaseText from '../ui/typography/BaseText';
import PreviewImage from '../../images/graphics/invoice.png';
import { Link, navigate } from 'gatsby';
import { PATH_PAGE } from '../../routes/paths';
function IncomingPayments({
  importedInvoiceData,
  getImportingInvoiceDetails,
  getValidatorList,
  deleteMarkAsPaid,
  getCompanyDetails,
  updateImportingInvoice,
  checkInvoiceValidation,
  cancelValidationSuccess,
  isFetchingInvoice,
  deleteImportedInvoice,
  trigger,
  checkSendEmail,
  checkValidateSendEmail
}) {
  const [addPayment, setAddPayment] = useState(false);
  const [invoicePaid, setInvoicePaid] = useState(false);
  const [openValidationModal, closeValidationModal] = useState(false);
  const [validators, setValidators] = useState([]);
  const [sendInvoice, setSendInvoice] = useState(false);
  const [validatorModal, setValidatorModal] = useState(false);
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [getMarkAsPaidData, setGetMarkAsPaidData] = useState('');
  const [companyDetails, setCompanyDetails] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [updatePayment, setUpdatePayment] = useState('');
  const [successMessage, setSuccessMessage] = useState({
    title: '',
    content: ''
  });
  const [paymentProofModal, setPaymentProofModal] = useState(false);
  const [proofDocument, setProofDocument] = useState('');
  const [sendEmailLoading, setSendEmailLoading] = useState(false);
  const [showAlreadySharedModal, setShowAlreadySharedModal] = useState(false);
  const location = useLocation();
  const params = new URLSearchParams(location?.search);
  const id = params.get('id');
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const handleToCloseValidationModal = () => {
    setValidatorModal(false);
  };
  const closeSnackbar = () => {
    setIsSnackBarOpen(false);
  };
  const closeDynamicModal = () => {
    setSendInvoice(false);
  };
  const closePaymentProofModal = () => {
    setPaymentProofModal(false);
  };
  const sendInvoiceByMail = () => {
    setValidatorModal(true);
  };
  const closeAddPaymentModal = () => {
    setAddPayment(false);
    setIsEdit(false);
  };
  const handleToAddPayment = () => {
    setAddPayment(true);
    setIsEdit(false);
  };
  const handleOpenValidation = async () => {
    closeValidationModal(true);
    setSendEmailLoading(true);
    const checkSendEmailResponse = await checkValidateSendEmail(id ? id : importedInvoiceData._id);
    if (checkSendEmailResponse?.data?.statusCode === API_CODE?.STATUS_200) {
      setShowAlreadySharedModal(false);
    } else {
      setShowAlreadySharedModal(true);
    }
    setSendEmailLoading(false);
  };
  const setHandlerInvoiceIsPaid = () => {
    getImportingInvoiceDetails();
    setInvoicePaid(true);
  };
  const onEditingMarkAsPaid = (paymentData) => {
    setUpdatePayment(paymentData);
    setIsEdit(true);
    setAddPayment(true);
  };
  const onDeleteMarkAsPaid = (paymentData) => {
    if (paymentData) {
      deleteMarkAsPaidHandler(paymentData);
    }
  };

  const handleDeleteInvalidInvoice = async () => {
    const deletedInvoiceResponse = await deleteImportedInvoice(id);
    if (deletedInvoiceResponse?.status === API_CODE.STATUS_200) {
      navigate(PATH_PAGE.invoiceList);
    }
  };

  const updateImportingInvoiceHandler = async (id, removedPayment) => {
    const payload = {
      invoiceId: id,
      status:
        importedInvoiceData?.payments?.length - 1 === 0 ? INVOICE_STATUS.INVOICE_UNPAID : INVOICE_STATUS.PARTIALLY_PAID,
      pendingAmount:
        importedInvoiceData?.pendingAmount >= 0 &&
        Number(importedInvoiceData?.pendingAmount) + removedPayment?.paidAmount
    };
    const getUpdatedInvoiceResponse = await updateImportingInvoice(payload);
    if (getUpdatedInvoiceResponse?.status === API_CODE?.STATUS_200) {
      getImportingInvoiceDetails();
    }
  };

  const copyInvoiceLink = () => {
    setIsSnackBarOpen(true);
    setSuccessMessage({
      ...successMessage,
      title: `Success`,
      content: `Link Copied sucessfully!`
    });
    copy(`${process.env.SITE_URL}${PATH_PAGE.incomingPublicInvoice}?id=${id}`);
  };

  const accountSettingDetails = async () => {
    const companyDetailsResponse = await getCompanyDetails();
    if (companyDetailsResponse?.status === API_CODE.STATUS_200) {
      setCompanyDetails(companyDetailsResponse?.data?.data);
    }
  };

  const deleteMarkAsPaidHandler = async (paymentId) => {
    const deletemarkAsPaidResponse = await deleteMarkAsPaid({
      params: {
        invoiceId: id,
        paymentId: paymentId?._id
      }
    });
    if (deletemarkAsPaidResponse?.status === API_CODE.STATUS_200) {
      updateImportingInvoiceHandler(id, paymentId);
      setIsSnackBarOpen(true);
      setSuccessMessage({
        ...successMessage,
        title: `Success`,
        content: `Payment Deleted sucessfully!`
      });
    }
  };

  const getListOfValidators = async () => {
    const validatorResponse = await getValidatorList();
    if (validatorResponse?.status === API_CODE.STATUS_200) {
      setValidators(validatorResponse?.data?.data);
    }
  };

  const remaining = importedInvoiceData?.payments?.reduce((accumulator, object) => {
    return accumulator + object.paidAmount;
  }, 0);
  const handleToSharePaidInvoice = async (id) => {
    setSendInvoice(true);
    setSendEmailLoading(true);
    const checkSendEmailResponse = await checkSendEmail(id ? id : importedInvoiceData._id);
    if (checkSendEmailResponse?.data?.statusCode === API_CODE?.STATUS_200) {
      setShowAlreadySharedModal(false);
    } else {
      setShowAlreadySharedModal(true);
    }
    setSendEmailLoading(false);
  };

  useEffect(() => {
    if (trigger) {
      handleToSharePaidInvoice();
    }
  }, [trigger]);

  useEffect(() => {
    getListOfValidators();
    accountSettingDetails();
  }, []);
  const openPaymentProof = (document) => {
    setProofDocument(document);
    setPaymentProofModal(true);
  };

  const cancelValidation = async () => {
    let payload = {
      invoiceId: importedInvoiceData?._id,
      validationStatus: INVOICE_STATUS.NONE,
      status: INVOICE_STATUS.INVOICE_UNPAID
    };
    const cancelValidationResponse = await checkInvoiceValidation(payload);
    if (cancelValidationResponse?.status === API_CODE.STATUS_200) {
      setIsSnackBarOpen(true);
      setSuccessMessage({
        ...successMessage,
        title: `Success`,
        content: `Validation canceled!`
      });
      cancelValidationSuccess();
    }
  };

  const handleShare = (e) => {
    e.preventDefault();
    const title = 'View Your Invoice';
    const ahref = `${process.env.SITE_URL}${PATH_PAGE.incomingPublicInvoice}?id=${id}`;
    const text = `${title} ${ahref}`;
    const encodedText = encodeURIComponent(text);
    const encodedAhref = encodeURIComponent(ahref);
    const encodedImage = encodeURIComponent(PreviewImage);
    let link;
    switch (e.currentTarget.id) {
      case 'facebook':
        link = `${SOCIAL_SHARE_LINK.FACEBOOK_URL}${ahref}`;
        open(link);
        break;
      case 'telegram':
        link = `${SOCIAL_SHARE_LINK.TELEGRAM_URL}${encodedAhref}&text=${title}`;
        open(link);
        break;
      case 'whatsapp':
        link = `${SOCIAL_SHARE_LINK.WHATSAPP_URL}${encodedText}&source&data&abid=12345&app_absent=true&thumb=${encodedImage}`;
        open(link);
        break;
      default:
        break;
    }
  };
  const open = (socialLink) => {
    isBrowser() && window.open(socialLink, '_blank');
  };

  const lastPaymentRecorded = importedInvoiceData?.payments?.slice(-1)[0];
  return (
    <div className="bg-white rounded-lg px-8 py-6 incoming-payments-container">
      {isFetchingInvoice ? (
        <div className="bg-white rounded-lg px-8 py-6">
          <StatrysLoader />
        </div>
      ) : (
        <>
          <MDSubtitleText fontWeight="text-bold" title={invoicePaid ? 'Linked Payments' : 'Payments'} />
          <H3HeaderText
            className="mt-4 mb-2"
            title={`${concatenateSelectedCurrency(importedInvoiceData?.currency, importedInvoiceData?.totalAmount)}`}
          />
          <XSText
            textColor={
              importedInvoiceData?.payments?.length > 0
                ? importedInvoiceData?.paymentStatus === PAYMENT_STATUS_INVOICE.PAID
                  ? 'text-green-500'
                  : 'text-gray-450'
                : 'text-gray-700'
            }
            className="mb-10"
            title={`${
              importedInvoiceData?.payments?.length > 0
                ? importedInvoiceData?.paymentStatus === PAYMENT_STATUS_INVOICE.PAID
                  ? `Paid in full on ${fDate(lastPaymentRecorded?.paidDate)}`
                  : `${remainingAmount(
                      importedInvoiceData?.currency,
                      importedInvoiceData?.totalAmount,
                      remaining
                    )} remaining by ${fDate(importedInvoiceData?.dueDate)}`
                : `due on ${fDate(importedInvoiceData?.dueDate)}`
            }`}
          />

          <DynamicModal openDynamicModal={sendInvoice} closeDynamicModal={closeDynamicModal} minWidth="520px">
            <div className="flex flex-col items-center mt-8">
              <MDSubtitleText
                title={`Share your invoice with your \nsupplier`}
                fontWeight="text-bold"
                className="whitespace-pre-line line-height-26"
                textAlign="text-center"
              />
              {sendEmailLoading ? (
                <StatrysLoader />
              ) : (
                <div>
                  {showAlreadySharedModal ? (
                    <div className="bg-green-200 rounded-lg p-4 my-8 flex gap-4 items-start">
                      <img src={TimeClockIcon} alt="time-clock" />
                      <div>
                        <XSText fontWeight="text-bold" title="You have already shared this invoice by email today." />
                        <XSText className="mt-2" title="Wait until tomorrow to send a reminder." />
                      </div>
                    </div>
                  ) : (
                    <div className="flex flex-col items-center">
                      <PrimaryButton
                        id="incoming-payment-send-send-by-email-btn"
                        className="invoice-primary-button mt-8"
                        caption="Send by email"
                        fontWeight="text-bold"
                        onClick={() => sendInvoiceByMail()}
                      />
                    </div>
                  )}
                </div>
              )}
              <div className="flex mt-4 mb-2 text-center">
                <div className="cursor-pointer" id="facebook" onClick={handleShare}>
                  <FacebookIcon />
                  <BaseText className="social-share-title" fontSize="" title="by Facebook" />
                </div>
                <div className="ml-8 cursor-pointer" id="whatsapp" onClick={handleShare}>
                  <WhatsAppIcon />
                  <BaseText className="social-share-title" fontSize="" title="by Whatsapp" />
                </div>
                <div className="ml-8 cursor-pointer" id="telegram" onClick={handleShare}>
                  <Telegram />
                  <BaseText className="social-share-title" fontSize="" title="by Telegram" />
                </div>
              </div>
              <XSText
                title={
                  <div>
                    or copy a shareable{' '}
                    <Link
                      className=" text-coral-500"
                      target="_blank"
                      to={`${process.env.SITE_URL}${PATH_PAGE.incomingPublicInvoice}?id=${id}`}
                    >
                      link
                    </Link>
                  </div>
                }
                className="leading-6 mt-8"
              />
              <div className="flex flex-row items-center gap-2 mt-2">
                <div className="border-gray-200 rounded-lg border">
                  <MSText
                    title={`${process.env.SITE_URL}${PATH_PAGE.incomingPublicInvoice}?id=${id}`}
                    fontWeight="text-medium"
                    className="leading-6 p-4"
                    textColor="text-coral-500"
                  />
                </div>
                <div className="cursor-pointer" onClick={copyInvoiceLink}>
                  <CopyClipboard />
                </div>
              </div>
            </div>
          </DynamicModal>

          <DynamicModal
            openDynamicModal={paymentProofModal}
            closeDynamicModal={closePaymentProofModal}
            minWidth="520px"
          >
            <a className="flex justify-center items-center" href={proofDocument} target="_blank">
              {getFileExtension(proofDocument) === FILE_EXTENTION_TYPE.PDF ? (
                <div className="h-96 mt-10">
                  <Document file={proofDocument}>
                    <Page pageNumber={1} width={700} height={100} />
                  </Document>
                </div>
              ) : (
                <img src={proofDocument} alt="payment proof" />
              )}
            </a>
          </DynamicModal>

          {(importedInvoiceData?.status === INVOICE_STATUS.INVOICE_PAID ||
            importedInvoiceData?.status === INVOICE_STATUS.INVOICE_UNPAID ||
            importedInvoiceData?.status === INVOICE_STATUS.PARTIALLY_PAID) && (
            <>
              {importedInvoiceData?.payments?.reverse().map((payment) => (
                <>
                  <div className="bg-customBg-mainBg pt-2 pb-4 px-4  rounded-lg mb-2">
                    <div className="flex justify-between items-center">
                      <XMSText title={payment?.paidDate && convertDateToDDMMYYYY(payment?.paidDate)} />
                      <div className="cursor-pointer">
                        <MArkAsPaidMenu
                          onEditingMarkAsPaid={() => onEditingMarkAsPaid(payment)}
                          onDeleteMarkAsPaid={() => onDeleteMarkAsPaid(payment)}
                        />
                      </div>
                    </div>
                    <XSText
                      className="mb-2"
                      textColor="text-coral-500"
                      title={
                        payment?.paidAmount &&
                        concatenateSelectedCurrency(importedInvoiceData?.currency, payment?.paidAmount)
                      }
                    />
                    <XMSText title={payment?.paymentType ? payment?.paymentType : 'Added Manually '} />
                    {payment?.paymentProofSignedUrl && <Line color="bg-white" className="my-2" />}
                    {payment?.paymentProofSignedUrl && (
                      <div
                        className="flex flex-row gap gap-2 mt-4 cursor-pointer"
                        onClick={() => openPaymentProof(payment?.paymentProofSignedUrl)}
                      >
                        <img src={Attachment} alt="attachment" />
                        <MSText
                          title={stringTruncate(takeOutImageName('View payment proof document'), 28)}
                          textColor="text-coral-500"
                          fontWeight="text-medium"
                        />
                      </div>
                    )}
                  </div>
                </>
              ))}
            </>
          )}
          <div className="mt-10">
            {importedInvoiceData?.validationStatus === INVOICE_STATUS.NONE &&
              importedInvoiceData?.status !== INVOICE_STATUS.INVOICE_PAID &&
              importedInvoiceData?.payments.length === 0 && (
                <>
                  {validators?.length > 0 ? (
                    <>
                      <PrimaryButton
                        id="incoming-payment-request-validation-btn"
                        caption="Request validation"
                        type="small"
                        bgColor="bg-white"
                        className="incoming-payment-white-button mb-2"
                        onClick={handleOpenValidation}
                      />
                      <RequestValidation
                        openValidationModal={openValidationModal}
                        closeValidationModal={() => closeValidationModal(false)}
                        showAlreadySharedModal={showAlreadySharedModal}
                        sendEmailLoading={sendEmailLoading}
                        emailInvoiceData={importedInvoiceData}
                        companyDetails={companyDetails}
                        getImportingInvoiceDetails={getImportingInvoiceDetails}
                        validatorsList={validators}
                        getListOfValidators={() => getListOfValidators()}
                      />
                    </>
                  ) : (
                    <ShareInvoiceByMail
                      title="Request validation"
                      type="small"
                      bgColor="bg-white"
                      className="incoming-payment-white-button mb-2"
                      modalTitle="Ask somebody to validate the invoice"
                      emailInvoiceData={importedInvoiceData}
                      companyDetails={companyDetails}
                      getImportingInvoiceDetails={getImportingInvoiceDetails}
                    />
                  )}
                </>
              )}
            {importedInvoiceData?.validationStatus === INVOICE_STATUS.AWAITING_VALIDATION && (
              <PrimaryButton
                id="incoming-payment-cancel-validation-btn"
                bgColor="bg-white"
                caption="Cancel validation"
                className="my-2 incoming-payment-white-button"
                type="small"
                onClick={cancelValidation}
              />
            )}

            {importedInvoiceData?.status !== INVOICE_STATUS.INVOICE_PAID &&
              importedInvoiceData?.validationStatus !== INVOICE_STATUS.INVALID && (
                <PrimaryButton
                  id="incoming-payment-record-payment-btn"
                  bgColor="bg-white"
                  caption="Record payment"
                  className="mb-2 incoming-payment-white-button"
                  type="small"
                  onClick={handleToAddPayment}
                />
              )}

            {importedInvoiceData?.validationStatus === INVOICE_STATUS.INVALID && (
              <PrimaryButton
                id="incoming-payment-delete-invoice"
                bgColor="bg-white"
                caption="Delete Invoice"
                className="mb-2 incoming-payment-white-button"
                type="small"
                onClick={handleDeleteInvalidInvoice}
              />
            )}

            {importedInvoiceData?.status === INVOICE_STATUS.INVOICE_PAID && (
              <PrimaryButton
                id="incoming-payment-share-btn"
                bgColor="bg-white"
                caption="Share"
                className="incoming-payment-white-button"
                type="small"
                onClick={handleToSharePaidInvoice}
              />
            )}
          </div>
          <AddPaymentModal
            isEdit={isEdit}
            openDynamicModal={addPayment}
            importedInvoiceData={importedInvoiceData}
            closeDynamicModal={closeAddPaymentModal}
            setHandlerInvoiceIsPaid={setHandlerInvoiceIsPaid}
            getImportingInvoiceDetails={getImportingInvoiceDetails}
            getMarkAsPaidData={getMarkAsPaidData}
            updatePayment={updatePayment}
          />

          <ShareValidationRequestEmail
            openValidatioEmailModal={validatorModal}
            closeShareModal={() => setSendInvoice(false)}
            closeValidationMailDynamicModal={() => handleToCloseValidationModal()}
            modalTitle="Send Email"
            emailInvoiceData={importedInvoiceData}
            companyDetails={companyDetails}
            getImportingInvoiceDetails={getImportingInvoiceDetails}
            getListOfValidators={getListOfValidators}
          />

          <ShareInvoiceByEmail
            openValidatioEmailModal={validatorModal}
            closeValidationMailDynamicModal={() => handleToCloseValidationModal()}
            modalTitle="Share your paid invoice"
            emailInvoiceData={importedInvoiceData}
            companyDetails={companyDetails}
            getImportingInvoiceDetails={getImportingInvoiceDetails}
            getListOfValidators={getListOfValidators}
          />

          <Snackbar
            open={isSnackBarOpen}
            handleClose={closeSnackbar}
            sx={{ bgcolor: MUI_COLORS.LIGHT_GREEN, border: '1px solid', borderColor: MUI_COLORS.GREEN }}
            title={
              <div className="flex">
                <div className="flex items-center">
                  <Image className="w-5 h-5" src={SuccessIcon} />
                </div>
                <div className="ml-4">
                  <XSText fontWeight="text-bold" textColor="text-gray-500" title={successMessage?.title}></XSText>
                  <MSText textColor="text-gray-500" title={successMessage?.content}></MSText>
                </div>
              </div>
            }
          />
        </>
      )}
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    getValidatorList: () => dispatch(importedInvoiceReducer.getRequestValidators()),
    deleteMarkAsPaid: (payload) => dispatch(incomingInvoiceReducer.deleteMarkAsPaid(payload)),
    getCompanyDetails: () => dispatch(accountSettingActions.getCompanyDetails()),
    updateImportingInvoice: (invoiceData) => dispatch(importingInvoiceReducer.editImportingInvoice(invoiceData)),
    checkInvoiceValidation: (payload) => dispatch(incomingInvoiceReducer.checkInvoiceValidation(payload)),
    deleteImportedInvoice: (invoiceId) => dispatch(importingInvoiceReducer.deleteImportedInvoice(invoiceId)),
    checkSendEmail: (invoiceId) => dispatch(commonActions.checkSendEmail(invoiceId)),
    checkValidateSendEmail: (invoiceId) => dispatch(commonActions.checkValidateSendEmail(invoiceId))
  };
};

export default connect(null, mapDispatchToProps)(IncomingPayments);

IncomingPayments.propTypes = {
  importedInvoiceData: PropTypes.any,
  getImportingInvoiceDetails: PropTypes.func,
  deleteMarkAsPaid: PropTypes.func,
  getCompanyDetails: PropTypes.func,
  updateImportingInvoice: PropTypes.func,
  checkInvoiceValidation: PropTypes.func,
  cancelValidationSuccess: PropTypes.func
};

import * as React from 'react';
import '../../styles/public-view-invoice.scss';
import BaseText from '../ui/typography/BaseText';
import { concatenateSelectedCurrency, convertDateToDDMMYYYY } from '../../helper/helpers';
import PublicViewInvoicePaymentDetails from './PublicViewInvoicePaymentDetails';
import StatrysLoader from '../ui/loaders/StatrysLoader';
import InvoiceStatus from '../ui/InvoiceStatus';
import { INVOICE_STATUS } from '../../constants/enum';

const ViewInvoice = ({ getInvoiceDetails, isGetInvoiceDetailsLoading, isInternalUserView }) => {
  return (
    <>
      <div className="bg-white rounded-lg public-invoice-view-container md:mb-0 mb-10">
        {isGetInvoiceDetailsLoading ? (
          <StatrysLoader />
        ) : (
          <>
            <div className="flex flex-col md:flex-row justify-between">
              <div className="w-3/5">
                {getInvoiceDetails?.logoPublic && (
                  <img className="invoice-view-image" src={getInvoiceDetails?.logoPublic} alt="document" />
                )}
                {(getInvoiceDetails?.companyDetails?.firstName || getInvoiceDetails?.companyDetails?.companyName) && (
                  <BaseText
                    fontSize="public-invoice-text-md"
                    className="mt-4"
                    textColor="text-blue-300"
                    title={
                      <div>
                        {getInvoiceDetails?.companyDetails?.companyName && (
                          <div className="public-invoice-from-to-name">
                            <div className="my-1">{getInvoiceDetails?.companyDetails?.companyName}</div>
                          </div>
                        )}
                        {!getInvoiceDetails?.companyDetails?.companyName ? (
                          <div className="public-invoice-from-to-name">
                            <div className="capitalize my-1">
                              {getInvoiceDetails?.companyDetails?.firstName}{' '}
                              {getInvoiceDetails?.companyDetails?.lastName}
                            </div>
                          </div>
                        ) : (
                          <div className="capitalize my-1">
                            {' '}
                            {getInvoiceDetails?.companyDetails?.firstName} {getInvoiceDetails?.companyDetails?.lastName}
                          </div>
                        )}
                        {getInvoiceDetails?.companyDetails?.registrationNumber && (
                          <div className="my-1"># {getInvoiceDetails?.companyDetails?.registrationNumber}</div>
                        )}
                        {(getInvoiceDetails?.companyDetails?.address ||
                          getInvoiceDetails?.companyDetails?.townCity ||
                          getInvoiceDetails?.companyDetails?.state ||
                          getInvoiceDetails?.companyDetails?.postalCode) && (
                          <div className="capitalize flex gap-1 my-1">
                            <div>
                              {getInvoiceDetails?.companyDetails?.address} {getInvoiceDetails?.companyDetails?.townCity}{' '}
                              {getInvoiceDetails?.companyDetails?.state} {getInvoiceDetails?.companyDetails?.postalCode}
                            </div>
                          </div>
                        )}
                        {getInvoiceDetails?.companyDetails?.country && (
                          <div className="capitalize my-1">{getInvoiceDetails?.companyDetails?.country}</div>
                        )}
                        {getInvoiceDetails?.companyDetails?.website && (
                          <div className="my-1">{getInvoiceDetails?.companyDetails?.website}</div>
                        )}
                        {getInvoiceDetails?.companyDetails?.email && (
                          <div className="my-1">{getInvoiceDetails?.companyDetails?.email}</div>
                        )}
                      </div>
                    }
                  />
                )}
                {(getInvoiceDetails?.contact?.firstName || getInvoiceDetails?.contact?.companyName) && (
                  <>
                    <BaseText
                      fontSize="public-invoice-text-md"
                      textColor="text-gray-600"
                      className="mt-4"
                      title="Billed To"
                      lineHeight="leading-4"
                    ></BaseText>
                    <BaseText
                      fontSize="public-invoice-text-md"
                      className="mt-2"
                      textColor="text-blue-300"
                      title={
                        <div>
                          {getInvoiceDetails?.contact?.companyName && (
                            <div className="public-invoice-from-to-name">
                              <div className="my-1">{getInvoiceDetails?.contact?.companyName}</div>
                            </div>
                          )}
                          {getInvoiceDetails?.contact?.registrationNumber && (
                            <div className="my-1"># {getInvoiceDetails?.contact?.registrationNumber}</div>
                          )}
                          {(getInvoiceDetails?.contact?.address ||
                            getInvoiceDetails?.contact?.townCity ||
                            getInvoiceDetails?.contact?.state ||
                            getInvoiceDetails?.contact?.postalCode) && (
                            <div className="capitalize my-1">
                              {getInvoiceDetails?.contact?.address} {getInvoiceDetails?.contact?.townCity}{' '}
                              {getInvoiceDetails?.contact?.state} {getInvoiceDetails?.contact?.postalCode}
                            </div>
                          )}
                          {getInvoiceDetails?.contact?.country && (
                            <div className="capitalize my-1">{getInvoiceDetails?.contact?.country}</div>
                          )}
                          {getInvoiceDetails?.contact?.website && (
                            <div className="my-1">{getInvoiceDetails?.contact?.website}</div>
                          )}
                        </div>
                      }
                    />
                    <BaseText
                      fontSize="public-invoice-text-md"
                      textColor="text-blue-300"
                      className="mt-6"
                      title={
                        <div>
                          {!getInvoiceDetails.contact?.companyName ? (
                            <div className="capitalize public-invoice-from-to-name">
                              <div className="my-1">
                                {getInvoiceDetails?.contact?.firstName} {getInvoiceDetails?.contact?.lastName}
                              </div>
                            </div>
                          ) : (
                            <div className="capitalize my-1">
                              {' '}
                              {getInvoiceDetails?.contact?.firstName} {getInvoiceDetails?.contact?.lastName}
                            </div>
                          )}
                          {getInvoiceDetails?.contact?.email && (
                            <div className="my-1">{getInvoiceDetails?.contact?.email}</div>
                          )}
                        </div>
                      }
                    ></BaseText>
                  </>
                )}
              </div>
              <div className="md:w-2/5 w-full md:mt-0 mt-6">
                <div className="flex items-center gap-4 sm:justify-end justify-start">
                  {isInternalUserView && (
                    <InvoiceStatus
                      requiredInvoiceStatus={
                        getInvoiceDetails?.status ? getInvoiceDetails?.status : INVOICE_STATUS?.INVOICE_UNPAID
                      }
                    />
                  )}
                  <BaseText
                    className="flex public-invoice-details"
                    fontSize="public-invoice-details"
                    textColor="text-gray-500"
                    fontWeight="text-bold"
                    title="Invoice"
                    lineHeight="leading-10"
                  ></BaseText>
                </div>
                {getInvoiceDetails?.invoiceNumber && (
                  <div className="flex md:mt-4 mt-2 justify-between md:justify-end">
                    <BaseText
                      fontSize="public-invoice-text-md"
                      className="mr-4"
                      textColor="text-gray-600"
                      title="Invoice number"
                    ></BaseText>
                    <BaseText
                      fontSize="public-invoice-text-md"
                      textColor="text-blue-300"
                      lineHeight="leading-6"
                      title={getInvoiceDetails?.invoiceNumber}
                    ></BaseText>
                  </div>
                )}
                {getInvoiceDetails?.createDate && (
                  <div className="flex mt-4 justify-between md:justify-end">
                    <BaseText
                      fontSize="public-invoice-text-md"
                      className="mr-4"
                      textColor="text-gray-600"
                      title="Date:"
                    ></BaseText>
                    <BaseText
                      fontSize="public-invoice-text-md"
                      textColor="text-blue-300"
                      lineHeight="leading-6"
                      title={convertDateToDDMMYYYY(getInvoiceDetails?.createDate)}
                    ></BaseText>
                  </div>
                )}
                {getInvoiceDetails?.payments?.[0] ? (
                  <div className="flex mt-4 justify-between md:justify-end">
                    <BaseText
                      fontSize="public-invoice-text-md"
                      className="mr-4"
                      textColor="text-gray-600"
                      title="Paid at:"
                    ></BaseText>
                    <BaseText
                      fontSize="public-invoice-text-md"
                      textColor="text-blue-300"
                      lineHeight="leading-6"
                      title={convertDateToDDMMYYYY(getInvoiceDetails?.payments?.[0]?.paidDate)}
                    ></BaseText>
                  </div>
                ) : (
                  <div className="flex mt-4 justify-between md:justify-end">
                    <BaseText
                      fontSize="public-invoice-text-md"
                      className="mr-4"
                      textColor="text-gray-600"
                      title="Due:"
                    ></BaseText>
                    <BaseText
                      fontSize="public-invoice-text-md"
                      textColor="text-blue-300"
                      lineHeight="leading-6"
                      title={convertDateToDDMMYYYY(getInvoiceDetails?.dueDate)}
                    ></BaseText>
                  </div>
                )}
                <div className="flex mt-4 justify-between md:justify-end">
                  <BaseText
                    fontSize="public-invoice-text-md"
                    className="mr-4"
                    textColor="text-gray-600"
                    title="Invoice amount"
                  ></BaseText>
                  <BaseText
                    fontSize="public-invoice-text-md"
                    textColor="text-blue-300"
                    lineHeight="leading-6"
                    title={concatenateSelectedCurrency(getInvoiceDetails?.currency, getInvoiceDetails?.totalAmount)}
                  ></BaseText>
                </div>
                <div className="flex mt-4 justify-between  md:justify-end">
                  <BaseText
                    fontSize="public-invoice-text-md"
                    textColor="text-blue-300"
                    className="invoice-other-references"
                    title={getInvoiceDetails?.otherReferences}
                  ></BaseText>
                </div>
              </div>
            </div>

            {/* NEW INVOICE ITEMS */}
            <div className="mt-4">
              <div className="mt-6 mb-2">
                <BaseText
                  textColor="text-gray-600"
                  fontWeight="text-bold"
                  title="Description"
                  fontSize="text-xs"
                  lineHeight="leading-7"
                ></BaseText>
              </div>
              <div>
                <hr className="public-invoice-divider" />
              </div>
              {getInvoiceDetails?.items?.length > 0 && (
                <>
                  <div className="flex mt-3 mb-3 gap-5">
                    <div className="flex title-container">
                      <BaseText fontSize="public-invoice-text-md" textColor="text-gray-600" title="Item"></BaseText>
                    </div>

                    <div className="flex justify-between other-column-container">
                      <BaseText
                        fontSize="public-invoice-text-md"
                        textColor="text-gray-600"
                        className="qty-container"
                        textAlign="text-left"
                        title="Quantity"
                      ></BaseText>
                      <BaseText
                        fontSize="public-invoice-text-md"
                        textColor="text-gray-600"
                        className="unit-container"
                        textAlign="text-left"
                        title="Unit"
                      />
                      <BaseText
                        fontSize="public-invoice-text-md"
                        textColor="text-gray-600"
                        className="price-container"
                        textAlign="text-left"
                        title="Price"
                      ></BaseText>
                      <BaseText
                        fontSize="public-invoice-text-md"
                        textColor="text-gray-600"
                        className="total-container"
                        textAlign="text-right"
                        title="Total"
                      ></BaseText>
                    </div>
                  </div>
                  <div>
                    <hr className="public-invoice-divider" />
                  </div>
                </>
              )}
              {getInvoiceDetails?.items?.map((itemDetails) => {
                return (
                  <>
                    <div className="flex gap-5">
                      <div className="flex title-container mt-2">
                        <BaseText
                          fontSize="public-invoice-text-md"
                          textColor="text-blue-300"
                          title={itemDetails?.itemTitle}
                          fontWeight="text-bold"
                        ></BaseText>
                      </div>

                      <div className="flex justify-between other-column-container mt-2 ">
                        <BaseText
                          fontSize="public-invoice-text-md"
                          textColor="text-blue-300"
                          className="qty-container"
                          textAlign="text-left"
                          title={itemDetails?.itemQuantity}
                        ></BaseText>
                        <BaseText
                          fontSize="public-invoice-text-md"
                          textColor="text-blue-300"
                          className="unit-container"
                          textAlign="text-left"
                          title={itemDetails?.unit.name}
                        />
                        <BaseText
                          fontSize="public-invoice-text-md"
                          textColor="text-blue-300"
                          className="price-container"
                          textAlign="text-left"
                          title={concatenateSelectedCurrency(getInvoiceDetails?.currency, itemDetails?.itemPriceRate)}
                        ></BaseText>
                        <BaseText
                          fontSize="public-invoice-text-md"
                          textColor="text-blue-300"
                          className="total-container"
                          textAlign="text-right"
                          title={concatenateSelectedCurrency(getInvoiceDetails?.currency, itemDetails?.itemTotal)}
                        ></BaseText>
                      </div>
                    </div>
                    <div className="flex title-container mb-4 mt-2">
                      <BaseText
                        fontSize="public-invoice-text-md"
                        textColor="text-blue-300"
                        title={itemDetails?.itemDescription}
                      />
                    </div>
                    <div>
                      <hr className="public-invoice-divider" />
                    </div>
                  </>
                );
              })}
            </div>
            {/* GOODS */}
            <div className="mt-4">
              {getInvoiceDetails?.goods?.length > 0 && (
                <>
                  <div className="flex mt-3 mb-3">
                    <div className="flex w-7/12">
                      <div className="flex w-6/12">
                        <BaseText
                          fontSize="public-invoice-text-md"
                          textColor="text-gray-600"
                          textAlign="text-left"
                          title="Item"
                          lineHeight="leading-6"
                        ></BaseText>
                      </div>
                      <div className="flex w-6/12">
                        <BaseText
                          fontSize="public-invoice-text-md"
                          textColor="text-gray-600"
                          textAlign="text-left"
                          title="Description"
                          lineHeight="leading-6"
                        ></BaseText>
                      </div>
                    </div>
                    <div className="flex justify-between w-5/12">
                      <BaseText
                        fontSize="public-invoice-text-md"
                        textColor="text-gray-600"
                        className="w-1/5"
                        textAlign="text-left"
                        title="Rate"
                        lineHeight="leading-6"
                      ></BaseText>
                      <BaseText
                        fontSize="public-invoice-text-md"
                        textColor="text-gray-600"
                        className="w-2/5"
                        textAlign="text-center"
                        title="Price"
                        lineHeight="leading-6"
                      ></BaseText>
                      <BaseText
                        fontSize="public-invoice-text-md"
                        textColor="text-gray-600"
                        className="w-2/5"
                        textAlign="text-right"
                        title="Total"
                        lineHeight="leading-6"
                      ></BaseText>
                    </div>
                  </div>
                  <div>
                    <hr className="public-invoice-divider" />
                  </div>
                </>
              )}
              {getInvoiceDetails?.goods?.map((taskDetails, index) => {
                return (
                  <>
                    <div className="flex" key={index}>
                      <div className="flex w-7/12">
                        <div className="flex w-6/12 mt-2">
                          <BaseText
                            fontSize="public-invoice-text-md"
                            textColor="text-blue-300"
                            textAlign="text-left"
                            title={taskDetails?.itemTitle}
                            lineHeight="leading-6"
                          ></BaseText>
                        </div>
                        <div className="flex w-6/12 mt-2">
                          <BaseText
                            fontSize="public-invoice-text-md"
                            textColor="text-blue-300"
                            textAlign="text-left"
                            title={taskDetails?.itemDescription}
                            lineHeight="leading-6"
                          ></BaseText>
                        </div>
                      </div>
                      <div className="flex justify-between w-5/12 mt-2 mb-4">
                        <BaseText
                          fontSize="public-invoice-text-md"
                          textColor="text-blue-300"
                          className="w-1/5"
                          textAlign="text-left"
                          title={taskDetails?.itemQuantity}
                          lineHeight="leading-6"
                        ></BaseText>
                        <BaseText
                          fontSize="public-invoice-text-md"
                          textColor="text-blue-300"
                          className="w-2/5"
                          textAlign="text-center"
                          title={concatenateSelectedCurrency(getInvoiceDetails?.currency, taskDetails?.itemPrice)}
                          lineHeight="leading-6"
                        ></BaseText>
                        <BaseText
                          fontSize="public-invoice-text-md"
                          textColor="text-blue-300"
                          className="w-2/5"
                          textAlign="text-right"
                          title={concatenateSelectedCurrency(getInvoiceDetails?.currency, taskDetails?.itemTotal)}
                          lineHeight="leading-6"
                        ></BaseText>
                      </div>
                    </div>
                    <div>
                      <hr className="public-invoice-divider" />
                    </div>
                  </>
                );
              })}
            </div>
            {/* SERVICES */}
            <div className="mt-4">
              {getInvoiceDetails?.services?.length > 0 && (
                <>
                  <div className="flex mt-3 mb-3">
                    <div className="flex w-7/12">
                      <div className="flex w-6/12">
                        <BaseText
                          fontSize="public-invoice-text-md"
                          textColor="text-gray-600"
                          textAlign="text-left"
                          title="Item"
                          lineHeight="leading-6"
                        ></BaseText>
                      </div>
                      <div className="flex w-6/12">
                        <BaseText
                          fontSize="public-invoice-text-md"
                          textColor="text-gray-600"
                          textAlign="text-left"
                          title="Description"
                          lineHeight="leading-6"
                        ></BaseText>
                      </div>
                    </div>
                    <div className="flex justify-between w-5/12">
                      <BaseText
                        fontSize="public-invoice-text-md"
                        textColor="text-gray-600"
                        className="w-2/5"
                        textAlign="text-left"
                        title="Rate"
                        lineHeight="leading-6"
                      ></BaseText>
                      <BaseText
                        fontSize="public-invoice-text-md"
                        textColor="text-gray-600"
                        className="w-1/5"
                        textAlign="text-center"
                        title="Hours"
                        lineHeight="leading-6"
                      ></BaseText>
                      <BaseText
                        fontSize="public-invoice-text-md"
                        textColor="text-gray-600"
                        className="w-2/5"
                        textAlign="text-right"
                        title="Total"
                        lineHeight="leading-6"
                      ></BaseText>
                    </div>
                  </div>
                  <div>
                    <hr className="public-invoice-divider" />
                  </div>
                </>
              )}
              {getInvoiceDetails?.services?.map((taskDetails, index) => {
                return (
                  <>
                    <div className="flex" key={index}>
                      <div className="flex w-7/12">
                        <div className="flex w-6/12 mt-2">
                          <BaseText
                            fontSize="public-invoice-text-md"
                            textColor="text-blue-300"
                            textAlign="text-left"
                            title={taskDetails?.taskTitle}
                            lineHeight="leading-6"
                          ></BaseText>
                        </div>
                        <div className="flex w-6/12 mt-2">
                          <BaseText
                            fontSize="public-invoice-text-md"
                            textColor="text-blue-300"
                            textAlign="text-left"
                            title={taskDetails?.taskDescription}
                            lineHeight="leading-6"
                          ></BaseText>
                        </div>
                      </div>
                      <div className="flex justify-between w-5/12 mt-2 mb-4">
                        <BaseText
                          fontSize="public-invoice-text-md"
                          textColor="text-blue-300"
                          className="w-2/5"
                          textAlign="text-left"
                          title={concatenateSelectedCurrency(getInvoiceDetails?.currency, taskDetails?.taskRate)}
                          lineHeight="leading-6"
                        ></BaseText>
                        <BaseText
                          fontSize="public-invoice-text-md"
                          textColor="text-blue-300"
                          className="w-1/5"
                          textAlign="text-center"
                          title={taskDetails?.taskHours}
                          lineHeight="leading-6"
                        ></BaseText>
                        <BaseText
                          fontSize="public-invoice-text-md"
                          textColor="text-blue-300"
                          className="w-2/5"
                          textAlign="text-right"
                          title={concatenateSelectedCurrency(getInvoiceDetails?.currency, taskDetails?.taskTotal)}
                          lineHeight="leading-6"
                        ></BaseText>
                      </div>
                    </div>
                    <div>
                      <hr className="public-invoice-divider" />
                    </div>
                  </>
                );
              })}
            </div>

            <div className="public-invoice-account-section flex gap-6">
              <div className=" flex flex-col sm:w-3/5 w-full">
                {getInvoiceDetails?.paymentDetails && (
                  <>
                    <BaseText
                      fontSize="public-invoice-text-md"
                      textColor="text-gray-600"
                      title="Account Details"
                      lineHeight="leading-6"
                    ></BaseText>
                    <PublicViewInvoicePaymentDetails
                      getInvoiceDetails={getInvoiceDetails}
                      paymentDetails={getInvoiceDetails?.paymentDetails}
                    />
                  </>
                )}
                {getInvoiceDetails?.notes && (
                  <>
                    <BaseText
                      fontSize="public-invoice-text-md"
                      textColor="text-gray-600"
                      className="mt-4 public-invoice-notes-section"
                      title="Notes"
                      lineHeight="leading-6"
                    ></BaseText>

                    <BaseText
                      fontSize="public-invoice-text-md"
                      className="mt-2 invoice-account-details"
                      textColor="text-blue-300"
                      title={<div>{getInvoiceDetails.notes}</div>}
                    />
                  </>
                )}
              </div>
              <div className="sm:w-2/5 w-full">
                <div className="flex justify-between">
                  <BaseText
                    fontSize="public-invoice-text-md"
                    textColor="text-blue-300"
                    className="mb-4"
                    title={<div>Subtotal</div>}
                    lineHeight="leading-6"
                  ></BaseText>
                  <BaseText
                    fontSize="public-invoice-text-md"
                    textColor="text-blue-300"
                    className="mb-4"
                    textAlign="text-right"
                    title={concatenateSelectedCurrency(getInvoiceDetails?.currency, getInvoiceDetails?.subtotal)}
                    lineHeight="leading-6"
                  ></BaseText>
                </div>
                <div className="flex justify-between">
                  {getInvoiceDetails?.invoiceDiscount?.discountPercentage !== 0
                    ? getInvoiceDetails?.invoiceDiscount?.discountPercentage &&
                      getInvoiceDetails?.invoiceDiscount?.discountPercentage !== '0' && (
                        <>
                          <BaseText
                            fontSize="public-invoice-text-md"
                            textColor="text-blue-300"
                            className="mb-4 w-32"
                            title={
                              <span className="flex justify-between invoice-word-break">
                                <span>{getInvoiceDetails?.invoiceDiscount?.discountName}</span>
                                <span>{getInvoiceDetails?.invoiceDiscount?.discountPercentage}%</span>
                              </span>
                            }
                            lineHeight="leading-6"
                          ></BaseText>
                          <BaseText
                            fontSize="public-invoice-text-md"
                            textColor="text-blue-300"
                            className="mb-4"
                            textAlign="text-right"
                            title={`- ${concatenateSelectedCurrency(
                              getInvoiceDetails.currency,
                              getInvoiceDetails?.invoiceDiscount?.discountPrice
                            )}`}
                            lineHeight="leading-6"
                          ></BaseText>
                        </>
                      )
                    : null}
                </div>
                {getInvoiceDetails?.taxWithPrice?.map((taxDetails, index) => {
                  return (
                    <div className="flex justify-between">
                      <BaseText
                        fontSize="public-invoice-text-md"
                        textColor="text-blue-300"
                        className="mb-4 w-32"
                        title={
                          <span className="flex justify-between invoice-word-break">
                            <span>{taxDetails?.taxConcept}</span>
                            <span>{taxDetails?.taxPercentage}%</span>
                          </span>
                        }
                        lineHeight="leading-6"
                      ></BaseText>
                      <BaseText
                        fontSize="public-invoice-text-md"
                        textColor="text-blue-300"
                        className="mb-4"
                        textAlign="text-right"
                        title={
                          Number(taxDetails?.taxPrice) < 0
                            ? `-${concatenateSelectedCurrency(
                                getInvoiceDetails?.currency,
                                Math.abs(taxDetails?.taxPrice)
                              )}`
                            : concatenateSelectedCurrency(getInvoiceDetails.currency, taxDetails?.taxPrice)
                        }
                        lineHeight="leading-6"
                      ></BaseText>
                    </div>
                  );
                })}

                <div className="flex justify-between">
                  <BaseText
                    textColor="text-blue-300"
                    className="mb-4 invoice-total"
                    title={`Total (${getInvoiceDetails?.currency?.name})`}
                    fontSize="text-xs"
                    lineHeight="leading-6"
                  ></BaseText>
                  <BaseText
                    textColor="text-blue-300"
                    textAlign="text-right"
                    className="mb-4 invoice-total"
                    title={concatenateSelectedCurrency(getInvoiceDetails?.currency, getInvoiceDetails?.totalAmount)}
                    fontSize="text-xs"
                    lineHeight="leading-6"
                  ></BaseText>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ViewInvoice;

import React, { useState, useRef, useEffect } from 'react';
import TextInput from '../ui/inputs/TextInput';
import MultiTextInput from '../ui/inputs/MultiTextInput';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as userActions from './reduxStore/action';
import ValidationMessage from '../ui/ValidationMessage';
import { useForm } from 'react-hook-form';
import InvoiceAmount from './InvoiceAmount';
import SelectInputAutoComplete from '../ui/inputs/selectInputAutoComplete';
import InvoiceCurrenciesList from '../../data/invoice-currencies-list.json';
import moment from 'moment';
import InvoiceFrom from './InvoiceFrom';
import InvoiceTo from './InvoiceTo';
import InvoicePaymentDetails from './InvoicePaymentDetails';
import * as paymentActions from '../invoicePaymentDetails/reduxStore/action';
import StatrysLogo from '../../images/icons/statrysLogo.svg';
import { Page } from '@react-pdf/renderer';
import {
  invoiceInputBackgroundColor,
  defaultValidationMessage,
  itemsTotalPrice,
  tasksTotalPrice,
  newItemsTotalPrice,
  priceCalculatorThroughPercentage,
  concatenateSelectedCurrencyForPDF
} from '../../helper/helpers';
import InvoiceGoods from './InvoiceGoods';
import InvoiceItems from './InvoiceItems';
import InvoiceTasks from './InvoiceTasks';
import H4HeaderText from '../ui/typography/H4HeaderText';
import { MUI_COLORS, INVOICE_STATUS, API_CODE, INVOICE_STATUS_SELECT } from '../../constants/enum';
import BaseText from '../ui/typography/BaseText';
import InvoiceStatus from '../ui/InvoiceStatus';
import { PATH_PAGE } from '../../routes/paths';
import { Link, navigate } from 'gatsby';
import { getUTCBasedDate } from '../../utils/formatTime';
import { useLocation } from '@reach/router';
import '../../styles/pages/invoice.scss';
import { PAGE_TITLE } from '../../constants/enum';
import PrimaryButton from '../ui/buttons/PrimaryButton';
import MSText from '../ui/typography/MSText';
import HeaderBreadcrumbs from '../HeaderBreadcrumbs';
import CheckMark from '../../images/icons/customSvg/CheckMark';
import Snackbar from '../../components/Snackbar';
import Success from '../../images/icons/success-icon.svg';
import XSText from '../ui/typography/XSText';
import SnackBarImage from '../Image';
import StatrysLoader from '../ui/loaders/StatrysLoader';
import InvoicePayment from './InvoicePayment';
import * as profileActions from '../profile/reduxStore/action';
import RedirectToSignUpModal from '../invoiceGeneratorModal/RedirectToSignUpModal';
import InvoiceStatusSelector from '../ui/InvoiceStatusSelector';

function InvoiceDetails({
  invoiceData,
  getInvoiceDetails,
  createInvoice,
  isEdit,
  editInvoice,
  isEditDataStored,
  getInvoiceDetailById,
  invoiceCount,
  getCurrencyPreference,
  currencyPreference,
  getBankDetailsList,
  isTemporary,
  showGoodAndServices
}) {
  const { register, handleSubmit, errors, watch, reset, setValue } = useForm({
    mode: 'onChange',
    defaultValues: {
      invoiceNumber: !isTemporary ? getInvoiceDetails?.invoiceNumber : 'INV-000',
      dueDate: getInvoiceDetails?.invoiceDueDate && getUTCBasedDate(new Date(getInvoiceDetails?.invoiceDueDate), 'UTC'),
      dateCreated:
        getInvoiceDetails?.invoiceDateCreated &&
        getUTCBasedDate(new Date(getInvoiceDetails?.invoiceDateCreated), 'UTC'),
      accountDetails: getInvoiceDetails?.paymentDetails,
      notes: getInvoiceDetails?.invoiceNotes,
      invoiceOtherReferences: getInvoiceDetails?.invoiceOtherReferences
    }
  });
  const [currency, setCurrency] = useState(
    getInvoiceDetails?.invoiceCurrency ? getInvoiceDetails?.invoiceCurrency : InvoiceCurrenciesList[0]
  );
  const [requiredInvoiceStatus, setRequiredInvoiceStatus] = useState(INVOICE_STATUS.INVOICE_DRAFT);
  const watchAllFields = watch();
  const subtotal =
    itemsTotalPrice(getInvoiceDetails?.invoiceItems) +
    tasksTotalPrice(getInvoiceDetails?.invoiceTasks) +
    newItemsTotalPrice(getInvoiceDetails?.items);

  const discountPrice = priceCalculatorThroughPercentage(
    subtotal,
    getInvoiceDetails?.invoiceDiscount?.discountPercentage
  );
  const [autoFocus, setAutoFocus] = useState(false);
  const [preferredCurrency, setPreferredCurrency] = useState('');
  const [invoiceAsDraft, setInvoiceAsDraft] = useState(false);
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [saveButtonLoading, setSaveButtonLoading] = useState(false);
  const [finalizeButtonLoading, setFinalizeButtonLoading] = useState(false);
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [openPaymentDetailModal, setOpenPaymentDetailModal] = useState(false);
  const [isFinalize, setIsFinalize] = useState(false);
  const [openRedirectionModal, setOpenRedirectModal] = useState(false);
  const totalAfterDiscount = discountPrice ? subtotal - discountPrice : subtotal;
  const taxWithPrice = getInvoiceDetails?.invoiceTaxes?.map((taxDetails) => ({
    ...taxDetails,
    taxPrice: priceCalculatorThroughPercentage(totalAfterDiscount, taxDetails?.taxPercentage)
  }));
  let totalTaxAmount = taxWithPrice?.reduce((accumulator, taxDetails) => {
    return accumulator + taxDetails?.taxPrice;
  }, 0);
  let totalAmount = totalTaxAmount ? totalTaxAmount + totalAfterDiscount : totalAfterDiscount;
  const onCloseCurrency = () => {
    !currency?.name && setCurrency(InvoiceCurrenciesList[0]);
  };

  const [isDueDate, setIsDueDate] = useState(false);
  const [createDate, setCreateDate] = useState('');
  const [dueDate, setDueDate] = useState('');

  const getPreferedCurrency = async () => {
    const getCurrencyDataResponse = await getCurrencyPreference();
    if (getCurrencyDataResponse?.status === API_CODE.STATUS_200) {
      setPreferredCurrency(getCurrencyDataResponse?.data?.data);
      if (!isEdit) {
        setCurrency(
          getCurrencyDataResponse?.data?.data?.currency
            ? getCurrencyDataResponse?.data?.data?.currency
            : InvoiceCurrenciesList[0]
        );
      }
    }
  };

  const location = useLocation();
  const params = new URLSearchParams(location?.search);
  const id = params.get('id');

  useEffect(() => {
    if (!isTemporary) {
      if (location?.state?.openSavedSnackbar) {
        setIsSnackBarOpen(true);
      }
      getPreferedCurrency();
    }
  }, []);

  useEffect(() => {
    setCurrency(getInvoiceDetails?.invoiceCurrency ? getInvoiceDetails?.invoiceCurrency : InvoiceCurrenciesList[0]);
    setCreateDate(
      getInvoiceDetails?.invoiceDateCreated
        ? getUTCBasedDate(new Date(getInvoiceDetails?.invoiceDateCreated), 'UTC')
        : moment(new Date()).format('YYYY-MM-DD')
    );

    setDueDate(
      getInvoiceDetails?.invoiceDueDate
        ? getUTCBasedDate(new Date(getInvoiceDetails?.invoiceDueDate), 'UTC')
        : moment(new Date()).format('YYYY-MM-DD')
    );

    const intersectionObserverOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 1.0
    };

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.target === upperActionsRef.current) {
          upperActionsRef.current.style.visibility = entry.isIntersecting ? 'visible' : 'hidden';
        } else if (entry.target === lowerActionsRef.current) {
          lowerActionsRef.current.style.visibility = entry.isIntersecting ? 'visible' : 'hidden';
        } else {
          upperActionsRef.current.style.visibility = 'visible';
        }
      });
    }, intersectionObserverOptions);

    upperActionsRef.current && observer.observe(upperActionsRef.current);
    lowerActionsRef.current && observer.observe(lowerActionsRef.current);

    return () => {
      observer.disconnect();
    };
  }, [getInvoiceDetails?.invoiceCurrency]);

  const invoiceCountHandler = async (id) => {
    const payload = {
      contactId: id
    };
    const invoiceCountResponse = await invoiceCount(payload);
    if (invoiceCountResponse?.status === API_CODE.STATUS_200) {
      setInvoiceNumber(invoiceCountResponse?.data?.data);
      if (invoiceCountResponse?.data?.data && !isEdit && !invoiceNumber) {
        setValue('invoiceNumber', `INV-${invoiceCountResponse?.data?.data}`);
        setAutoFocus(true);
      }
    }
  };

  const invoiceNumerHandler = (e) => {
    setInvoiceNumber(e.target.value);
  };

  const [redirectToInvoiceUserData, setRedirectToInvoiceUserData] = useState('');
  let containerRef = useRef(null);
  const upperActionsRef = useRef(null);
  const lowerActionsRef = useRef(null);

  const remaining = getInvoiceDetails?.payments?.reduce((accumulator, object) => {
    return accumulator + object.paidAmount;
  }, 0);

  function compareDates(date1, date2) {
    const convertedDate1 = new Date(date1);
    const convertedDate2 = new Date(date2);
    const isFutureDueDate = convertedDate1 >= convertedDate2;
    if (isFutureDueDate) {
      setIsDueDate(false);
      return true;
    } else {
      setIsDueDate(true);
      return false;
    }
  }

  const handleDueDate = (event) => {
    setDueDate(event.target.value);
    compareDates(event.target.value, createDate);
  };

  const onSubmit = async (data) => {
    if (!isTemporary && compareDates(dueDate, createDate)) {
      const bankDetailsList = await getBankDetailsList();
      const bankDetailsListWithSelection = bankDetailsList?.data?.data?.map((obj) => ({
        ...obj,
        isSelected: getInvoiceDetails?.paymentDetails?.bankDetails?.find((bankDetail) => bankDetail?._id === obj?._id)
          ? true
          : false
      }));
      {
        if (
          (getInvoiceDetails?.fromFirstName || getInvoiceDetails?.fromCompanyName) &&
          (getInvoiceDetails?.toFirstName || getInvoiceDetails?.toCompanyName)
        ) {
          invoiceAsDraft ? setSaveButtonLoading(true) : setFinalizeButtonLoading(true);
          invoiceData({
            invoiceOtherReferences: data?.invoiceOtherReferences,
            invoiceNumber: data?.invoiceNumber,
            invoiceDateCreated: data?.dateCreated,
            invoiceDueDate: data?.dueDate,
            paymentDetails: data?.paymentDetails,
            invoiceNotes: data?.notes,
            currency: currency
          });
        }
      }
      const invoiceCreateAndEditPayload = {
        companyDetails: {
          firstName: getInvoiceDetails?.fromFirstName,
          lastName: getInvoiceDetails?.fromLastName,
          email: getInvoiceDetails?.fromEmail,
          companyName: getInvoiceDetails?.fromCompanyName,
          registrationNumber: getInvoiceDetails?.fromRegistrationNumber,
          website: getInvoiceDetails?.fromWebsite,
          address: getInvoiceDetails?.fromAddress,
          city: getInvoiceDetails?.fromTownCity,
          state: getInvoiceDetails?.fromState,
          postalCode: getInvoiceDetails?.fromPostalCode,
          country: getInvoiceDetails?.fromCountry
        },
        contact: {
          contactId: getInvoiceDetails?.toId,
          firstName: getInvoiceDetails?.toFirstName,
          lastName: getInvoiceDetails?.toLastName,
          email: getInvoiceDetails?.toEmail,
          companyName: getInvoiceDetails?.toCompanyName,
          registrationNumber: getInvoiceDetails?.toRegistrationNumber,
          website: getInvoiceDetails?.toWebsite,
          address: getInvoiceDetails?.toAddress,
          city: getInvoiceDetails?.toTownCity,
          state: getInvoiceDetails?.toState,
          postalCode: getInvoiceDetails?.toPostalCode,
          country: getInvoiceDetails?.toCountry,
          profileImagePublic: getInvoiceDetails?.toProfile
        },
        services: getInvoiceDetails?.invoiceTasks,
        goods: getInvoiceDetails?.invoiceItems,
        items: getInvoiceDetails?.items,
        taxWithPrice: getInvoiceDetails?.invoiceTaxes,
        invoiceDiscount: getInvoiceDetails?.invoiceDiscount,
        currency: currency,

        notes: data?.notes,
        invoiceNumber: data?.invoiceNumber,
        dueDate: data?.dueDate,
        createDate: data?.dateCreated,
        otherReferences: data?.invoiceOtherReferences,
        totalAmount: parseFloat(totalAmount).toFixed(2),
        subtotal: parseFloat(subtotal).toFixed(2),
        logoPublic: getInvoiceDetails?.invoiceImage?.preview,
        paymentDetails: {
          paypalDetails: { ...getInvoiceDetails?.paymentDetails?.paypalDetails },
          hsbcDetails: { ...getInvoiceDetails?.paymentDetails?.hsbcDetails },
          bankDetails: bankDetailsListWithSelection?.filter((bankData) => bankData?.isSelected === true),
          customPaymentDetails: { ...getInvoiceDetails?.paymentDetails?.customPaymentDetails },
          customTextDetails: { ...getInvoiceDetails?.paymentDetails?.customTextDetails }
        }
      };
      if (isEdit) {
        const requestedPayload = {
          invoiceId: id,
          pendingAmount: getInvoiceDetails?.payments?.length > 0 ? totalAmount - remaining : null,
          status: invoiceAsDraft
            ? getInvoiceDetails?.invoiceStatus
            : getInvoiceDetails?.invoiceStatus === 'Draft'
            ? INVOICE_STATUS.INVOICE_UNPAID
            : getInvoiceDetails?.invoiceStatus,
          ...invoiceCreateAndEditPayload
        };
        await editInvoiceHandler(requestedPayload);
        invoiceAsDraft ? setSaveButtonLoading(false) : setFinalizeButtonLoading(false);
      } else {
        if (!getInvoiceDetails?.fromFirstName && !getInvoiceDetails?.fromCompanyName) {
          invoiceData({
            fromError: true
          });
        }
        if (!getInvoiceDetails?.toFirstName && !getInvoiceDetails?.toCompanyName) {
          invoiceData({
            toError: true
          });
        }
        if (
          (getInvoiceDetails?.fromFirstName || getInvoiceDetails?.fromCompanyName) &&
          (getInvoiceDetails?.toFirstName || getInvoiceDetails?.toCompanyName)
        ) {
          const requestedPayload = {
            status: invoiceAsDraft ? INVOICE_STATUS.INVOICE_DRAFT : INVOICE_STATUS.INVOICE_UNPAID,
            ...invoiceCreateAndEditPayload
          };
          await createInvoiceHandler(requestedPayload);
          invoiceAsDraft ? setSaveButtonLoading(false) : setFinalizeButtonLoading(false);
        }
      }
      invoiceAsDraft ? setSaveButtonLoading(false) : setFinalizeButtonLoading(false);
    } else {
      invoiceData({
        invoiceOtherReferences: data?.invoiceOtherReferences,
        invoiceNumber: data?.invoiceNumber,
        invoiceDateCreated: data?.dateCreated,
        invoiceDueDate: data?.dueDate,
        invoiceNotes: data?.notes,
        currency: currency
      });
      setOpenRedirectModal(true);
      const invoiceCreateAndEditPayload = {
        companyDetails: {
          firstName: getInvoiceDetails?.fromFirstName,
          lastName: getInvoiceDetails?.fromLastName,
          email: getInvoiceDetails?.fromEmail,
          companyName: getInvoiceDetails?.fromCompanyName,
          registrationNumber: getInvoiceDetails?.fromRegistrationNumber,
          website: getInvoiceDetails?.fromWebsite,
          address: getInvoiceDetails?.fromAddress,
          city: getInvoiceDetails?.fromTownCity,
          state: getInvoiceDetails?.fromState,
          postalCode: getInvoiceDetails?.fromPostalCode,
          country: getInvoiceDetails?.fromCountry,
          logoPublic: getInvoiceDetails?.logoPublic
        },
        contact: {
          contactId: getInvoiceDetails?.toId,
          firstName: getInvoiceDetails?.toFirstName,
          lastName: getInvoiceDetails?.toLastName,
          email: getInvoiceDetails?.toEmail,
          companyName: getInvoiceDetails?.toCompanyName,
          registrationNumber: getInvoiceDetails?.toRegistrationNumber,
          website: getInvoiceDetails?.toWebsite,
          address: getInvoiceDetails?.toAddress,
          city: getInvoiceDetails?.toTownCity,
          state: getInvoiceDetails?.toState,
          postalCode: getInvoiceDetails?.toPostalCode,
          country: getInvoiceDetails?.toCountry,
          profileImagePublic: getInvoiceDetails?.toProfile
        },
        services: getInvoiceDetails?.invoiceTasks,
        goods: getInvoiceDetails?.invoiceItems,
        items: getInvoiceDetails?.items,
        taxWithPrice: getInvoiceDetails?.invoiceTaxes,
        invoiceDiscount: getInvoiceDetails?.invoiceDiscount,
        currency: currency,
        status: INVOICE_STATUS.INVOICE_DRAFT,

        notes: data?.notes,
        invoiceNumber: data?.invoiceNumber,
        dueDate: data?.dueDate,
        createDate: data?.dateCreated,
        otherReferences: data?.invoiceOtherReferences,
        totalAmount: parseFloat(totalAmount).toFixed(2),
        subtotal: parseFloat(subtotal).toFixed(2),
        logoPublic: getInvoiceDetails?.logoPublic,
        paymentDetails: {
          bankDetails: getInvoiceDetails?.paymentDetails?.bankDetails?.map((bankData) => {
            if (bankData?.isSelected === true) {
              const { _id, ...otherBankDetails } = bankData;
              return otherBankDetails;
            }
          }),
          paypalDetails: { ...getInvoiceDetails?.paymentDetails?.paypalDetails },
          hsbcDetails: { ...getInvoiceDetails?.paymentDetails?.hsbcDetails },
          customPaymentDetails: { ...getInvoiceDetails?.paymentDetails?.customPaymentDetails },
          customTextDetails: { ...getInvoiceDetails?.paymentDetails?.customTextDetails }
        }
      };
      setRedirectToInvoiceUserData(invoiceCreateAndEditPayload);
    }
  };

  const editInvoiceHandler = async (payload) => {
    const editInvoiceResponse = await editInvoice(payload);
    if (editInvoiceResponse?.status === API_CODE.STATUS_200) {
      invoiceAsDraft
        ? setIsSnackBarOpen(true)
        : navigate(`${PATH_PAGE.viewInvoice}?id=${payload.invoiceId}`, { state: { openShareModal: true } });
      getInvoiceDetailById && getInvoiceDetailById(id);
    }
  };

  const createInvoiceHandler = async (payload) => {
    const createInvoiceResponse = await createInvoice(payload);
    if (createInvoiceResponse?.status === API_CODE.STATUS_200) {
      handlerSetPreferredCurrency();
      if (invoiceAsDraft) {
        navigate(`${PATH_PAGE.editInvoice}?id=${createInvoiceResponse?.data?.data?._id}`, {
          state: { openSavedSnackbar: true }
        });
      } else {
        navigate(`${PATH_PAGE.viewInvoice}?id=${createInvoiceResponse?.data?.data?._id}`, {
          state: { openShareModal: true }
        });
      }
    }
  };
  const handlerSetPreferredCurrency = async () => {
    if (preferredCurrency?.currency === undefined || preferredCurrency?.currency === '') {
      const payload = {
        currency: currency
      };
      await currencyPreference(payload);
    }
  };

  const saveAsDraftHandler = async () => {
    if (
      (getInvoiceDetails?.invoiceTasks?.[0]?.taskTitle &&
        getInvoiceDetails?.invoiceTasks?.[0]?.taskRate &&
        getInvoiceDetails?.invoiceTasks?.[0]?.taskHours) ||
      (getInvoiceDetails?.invoiceItems?.[0]?.itemTitle &&
        getInvoiceDetails?.invoiceItems?.[0]?.itemPriceRate &&
        getInvoiceDetails?.invoiceItems?.[0]?.itemQuantity) ||
      (getInvoiceDetails?.items?.[0]?.itemTitle &&
        getInvoiceDetails?.items?.[0]?.itemPriceRate &&
        getInvoiceDetails?.items?.[0]?.itemQuantity)
    ) {
      setInvoiceAsDraft(true);
      setIsFinalize(false);
    } else {
      setIsFinalize(true);
    }
    handleSubmit(onSubmit);
  };
  const finalizeButtonHandler = async () => {
    if (!isEdit) {
      if (
        (getInvoiceDetails?.invoiceTasks?.[0]?.taskTitle &&
          getInvoiceDetails?.invoiceTasks?.[0]?.taskRate &&
          getInvoiceDetails?.invoiceTasks?.[0]?.taskHours) ||
        (getInvoiceDetails?.invoiceItems?.[0]?.itemTitle &&
          getInvoiceDetails?.invoiceItems?.[0]?.itemPriceRate &&
          getInvoiceDetails?.invoiceItems?.[0]?.itemQuantity) ||
        (getInvoiceDetails?.items?.[0]?.itemTitle &&
          getInvoiceDetails?.items?.[0]?.itemPriceRate &&
          getInvoiceDetails?.items?.[0]?.itemQuantity)
      ) {
        setIsFinalize(false);
      } else {
        setIsFinalize(true);
      }
      setInvoiceAsDraft(false);
      handleSubmit(onSubmit);
    } else {
      if (
        (getInvoiceDetails?.invoiceTasks?.[0]?.taskTitle &&
          getInvoiceDetails?.invoiceTasks?.[0]?.taskRate &&
          getInvoiceDetails?.invoiceTasks?.[0]?.taskHours) ||
        (getInvoiceDetails?.invoiceItems?.[0]?.itemTitle &&
          getInvoiceDetails?.invoiceItems?.[0]?.itemPriceRate &&
          getInvoiceDetails?.invoiceItems?.[0]?.itemQuantity) ||
        (getInvoiceDetails?.items?.[0]?.itemTitle &&
          getInvoiceDetails?.items?.[0]?.itemPriceRate &&
          getInvoiceDetails?.items?.[0]?.itemQuantity)
      ) {
        setIsFinalize(false);
      } else {
        setIsFinalize(true);
      }
      setInvoiceAsDraft(false);
      handleSubmit(onSubmit);
    }
  };
  const closeSnackbar = () => {
    setIsSnackBarOpen(false);
  };

  const handleInvoiceDetailsbyID = () => {
    getInvoiceDetailById && getInvoiceDetailById(id);
  };
  return (
    <>
      {isEditDataStored && getInvoiceDetails && (
        <Page title={!isTemporary ? (isEdit ? 'Edit Invoice' : 'Create Invoice') : 'Invoice Generator'}>
          {isTemporary ? (
            <>
              <div className="md:ml-0 ml-14 flex justify-between">
                <Link className="flex hover:no-underline" to={PATH_PAGE.invoiceList}>
                  <img src={StatrysLogo} alt="Statrys Logo" />
                </Link>
                <div>
                  <div className="flex flex-row gap-2" ref={upperActionsRef}>
                    <button positive form="invoice-form" type="submit">
                      <PrimaryButton
                        id="invoice-details-save-btn"
                        bgColor="bg-white"
                        isBorderRequired={false}
                        onClick={saveAsDraftHandler}
                        isLoading={saveButtonLoading}
                        caption={
                          <div className="flex gap-1 items-center">
                            <MSText textColor="text-coral-500" fontWeight="text-bold" title="Save" />
                          </div>
                        }
                        type="small"
                      />
                    </button>

                    <div className="flex gap-4">
                      <button positive form="invoice-form" type="submit">
                        <PrimaryButton
                          id="invoice-details-finalize-btn"
                          onClick={finalizeButtonHandler}
                          isLoading={finalizeButtonLoading}
                          caption={
                            <div className="flex gap-2 items-center">
                              <div className="w-6">
                                <CheckMark />
                              </div>
                              <MSText textColor="text-white" fontWeight="text-bold" title="Finalize" />
                            </div>
                          }
                          fontWeight="text-bold"
                          type="small"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <BaseText
                className="invoice-generator-heading mt-11 mb-6"
                fontWeight="text-bold"
                title="Invoice generator"
              />
              {redirectToInvoiceUserData && (
                <RedirectToSignUpModal
                  redirectToInvoiceUserData={redirectToInvoiceUserData}
                  openRedirectionModal={openRedirectionModal}
                  closeRedirectionModal={() => setOpenRedirectModal(false)}
                />
              )}
            </>
          ) : (
            <HeaderBreadcrumbs
              heading={isEdit ? PAGE_TITLE.EDIT_INVOICE : PAGE_TITLE.CREATE_INVOICE}
              links={[
                { name: PAGE_TITLE.GET_PAID, href: PATH_PAGE.invoiceList },
                { name: isEdit ? PAGE_TITLE.EDIT_INVOICE : PAGE_TITLE.CREATE_INVOICE }
              ]}
              action={
                <div className="flex flex-row gap-2" ref={upperActionsRef}>
                  {finalizeButtonLoading || saveButtonLoading ? (
                    <StatrysLoader className="absolute w-5" mainContainerClass="loader-for-invoice-button" />
                  ) : (
                    <>
                      <PrimaryButton
                        form="invoice-form"
                        buttonType="submit"
                        id="invoice-details-temp-save-btn"
                        bgColor="bg-white"
                        isBorderRequired={false}
                        onClick={saveAsDraftHandler}
                        isLoading={saveButtonLoading}
                        caption={
                          <div className="flex gap-1 items-center">
                            <MSText textColor="text-coral-500" fontWeight="text-bold" title="Save" />
                          </div>
                        }
                        type="small"
                      />

                      <div className="flex gap-4">
                        <PrimaryButton
                          id="invoice-details-temp-finalize-btn"
                          form="invoice-form"
                          buttonType="submit"
                          onClick={finalizeButtonHandler}
                          isLoading={finalizeButtonLoading}
                          caption={
                            <div className="flex gap-2 items-center">
                              <div className="w-6">
                                <CheckMark />
                              </div>
                              <MSText textColor="text-white" fontWeight="text-bold" title="Finalize" />
                            </div>
                          }
                          fontWeight="text-bold"
                          type="small"
                        />
                      </div>
                    </>
                  )}
                </div>
              }
            />
          )}
        </Page>
      )}

      <div className="mt-10">
        <div className="w-full">
          <div className="bg-white rounded-lg invoice-form-container">
            {isEditDataStored && getInvoiceDetails ? (
              <form id="invoice-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="flex flex-col md:flex-row gap-10">
                  <div className="w-full flex flex-col gap-4 pr-4 md:pr-0">
                    <InvoiceFrom isTemporary={isTemporary} isEdit={isEdit} />
                    <InvoiceTo isTemporary={isTemporary} getContactId={invoiceCountHandler} isEditValue={isEdit} />
                  </div>
                  <div className="w-full flex-col">
                    <div className="flex gap-4 justify-end">
                      {isEdit ? (
                        <div className="flex items-center item-menu-dropdown dropdown">
                          <div className="flex flex-row gap-2 items-center cursor-pointer">
                            <InvoiceStatus
                              requiredInvoiceStatus={
                                getInvoiceDetails?.invoiceStatus
                                  ? getInvoiceDetails?.invoiceStatus
                                  : INVOICE_STATUS?.INVOICE_UNPAID
                              }
                              isArrowImage={true}
                            />
                            <InvoiceStatusSelector
                              menuItems={INVOICE_STATUS_SELECT}
                              getInvoiceDetails={getInvoiceDetails}
                              handleInvoiceDetailsbyID={handleInvoiceDetailsbyID}
                            />
                          </div>
                        </div>
                      ) : (
                        <InvoiceStatus
                          requiredInvoiceStatus={
                            getInvoiceDetails?.invoiceStatus ? getInvoiceDetails?.invoiceStatus : requiredInvoiceStatus
                          }
                        />
                      )}
                      <p className="invoice-heading invoice-right-padding">Invoice</p>
                    </div>
                    <div className="flex flex-col gap-4 mt-4 w-full invoice-right-padding">
                      <TextInput
                        InputLabelProps={!isTemporary ? { shrink: isEdit ? true : autoFocus } : { shrink: true }}
                        onFocus={() => setAutoFocus(true)}
                        textFieldContainerClass="w-full"
                        defaultValue={getInvoiceDetails?.invoiceNumber ? getInvoiceDetails?.invoiceNumber : ''}
                        name="invoiceNumber"
                        label="Invoice number"
                        onChange={invoiceNumerHandler}
                        backgroundColor={invoiceInputBackgroundColor(watchAllFields?.invoiceNumber)}
                        inputRef={register({
                          required: false
                        })}
                        error={errors?.invoiceNumber ? true : false}
                        helperText={
                          errors?.invoiceNumber && (
                            <ValidationMessage title={defaultValidationMessage('Invoice number')} />
                          )
                        }
                      />
                      <TextInput
                        textFieldContainerClass="w-full"
                        defaultValue={
                          getInvoiceDetails?.invoiceDateCreated
                            ? getUTCBasedDate(new Date(getInvoiceDetails?.invoiceDateCreated), 'UTC')
                            : moment(new Date()).format('YYYY-MM-DD')
                        }
                        name="dateCreated"
                        label="Created date"
                        type="date"
                        backgroundColor={invoiceInputBackgroundColor(watchAllFields?.dateCreated)}
                        inputRef={register({
                          required: true
                        })}
                        onChange={(e) => setCreateDate(e.target.value)}
                        error={errors?.dateCreated ? true : false}
                        helperText={
                          errors?.dateCreated && <ValidationMessage title={defaultValidationMessage('Date created')} />
                        }
                      />
                      <TextInput
                        textFieldContainerClass="w-full"
                        defaultValue={
                          getInvoiceDetails?.invoiceDueDate
                            ? getUTCBasedDate(new Date(getInvoiceDetails?.invoiceDueDate), 'UTC')
                            : moment(new Date()).add(1, 'days').format('YYYY-MM-DD')
                        }
                        name="dueDate"
                        label="Due date"
                        type="date"
                        InputLabelProps={{
                          shrink: true
                        }}
                        onChange={(e) => handleDueDate(e)}
                        backgroundColor={invoiceInputBackgroundColor(watchAllFields.dueDate)}
                        inputRef={register({
                          required: false
                        })}
                        error={errors?.dueDate || isDueDate ? true : false}
                        helperText={
                          (errors?.dueDate && <ValidationMessage title={defaultValidationMessage('valid Date')} />,
                          isDueDate && <ValidationMessage title="Due Date should not be less than Created Date" />)
                        }
                      />
                      <div className="md:w-full flex md:flex-row flex-col items-center gap-4">
                        <div className="invoice-currency-selector">
                          <SelectInputAutoComplete
                            label="Currency"
                            name="currency"
                            options={InvoiceCurrenciesList}
                            defaultValue={
                              getInvoiceDetails?.invoiceCurrency ? getInvoiceDetails?.invoiceCurrency : currency
                            }
                            value={getInvoiceDetails?.invoiceCurrency ? getInvoiceDetails?.invoiceCurrency : currency}
                            setSelectedValue={setCurrency}
                            onClose={onCloseCurrency}
                            disableClearable={true}
                          />
                        </div>
                        <div
                          className="w-full px-4 flex items-center justify-end border bg-customBg-mainBg border-gray-200 rounded-lg break-all "
                          style={{ height: '68px' }}
                        >
                          <H4HeaderText title={concatenateSelectedCurrencyForPDF(currency, totalAmount)} />
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col gap-4 invoice-right-padding">
                      <MultiTextInput
                        inputContainerClass="mt-4"
                        defaultValue={getInvoiceDetails?.invoiceOtherReferences}
                        name="invoiceOtherReferences"
                        label="Other references"
                        backgroundColor={invoiceInputBackgroundColor(watchAllFields?.invoiceOtherReferences)}
                        inputRef={register({
                          required: false
                        })}
                        error={errors?.invoiceOtherReferences ? true : false}
                        helperText={
                          errors?.invoiceOtherReferences && (
                            <ValidationMessage title={defaultValidationMessage('invoiceOtherReferences')} />
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
                <BaseText className="mt-6" title="Description" textColor="text-gray-600" fontWeight="text-bold" />
                <hr className="invoice-solid-divider mt-3 mb-2" />
                {(isEdit && getInvoiceDetails?.items?.length > 0) || !showGoodAndServices ? (
                  <InvoiceItems
                    isFinalize={isFinalize}
                    selectedCurrency={currency}
                    register={register}
                    errors={errors}
                    isEdit={isEdit}
                  />
                ) : null}
                {((isEdit && getInvoiceDetails?.goods?.length > 0) || getInvoiceDetails?.invoiceTasks?.length > 0) && (
                  <>
                    <InvoiceGoods
                      isFinalize={isFinalize}
                      selectedCurrency={currency}
                      register={register}
                      errors={errors}
                      isEdit={isEdit}
                    />
                    <InvoiceTasks
                      isFinalize={isFinalize}
                      selectedCurrency={currency}
                      register={register}
                      errors={errors}
                      isEdit={isEdit}
                    />
                  </>
                )}

                <hr className="invoice-divider mt-6 mb-2" />
                <div className="flex md:flex-row flex-col gap-6">
                  <div className="w-full flex flex-col gap-2 pr-4 md:pr-0">
                    <InvoicePaymentDetails isTemporary={isTemporary} />
                  </div>
                  <div className="w-full flex flex-col gap-2 pr-4 md:pr-0">
                    <InvoiceAmount selectedCurrency={currency} isEdit={isEdit} />
                    <TextInput
                      defaultValue={getInvoiceDetails?.invoiceNotes}
                      name="notes"
                      label="Notes"
                      backgroundColor={invoiceInputBackgroundColor(watchAllFields?.notes)}
                      inputRef={register({
                        required: false
                      })}
                    />
                  </div>
                </div>
              </form>
            ) : (
              <div className="flex justify-center items-center">
                <StatrysLoader />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="flex justify-end mt-8" ref={lowerActionsRef}>
        <div className="flex flex-row gap-2">
          <PrimaryButton
            form="invoice-form"
            buttonType="submit"
            id="invoice-details-bottom-save-btn"
            bgColor="bg-white"
            isBorderRequired={false}
            onClick={saveAsDraftHandler}
            isLoading={saveButtonLoading}
            caption={
              <div className="flex gap-1 items-center">
                <MSText textColor="text-coral-500" fontWeight="text-bold" title="Save" />
              </div>
            }
            type="small"
          />

          <div className="flex gap-4">
            <PrimaryButton
              form="invoice-form"
              buttonType="submit"
              id="invoice-details-bottom-finalize-btn"
              onClick={finalizeButtonHandler}
              isLoading={finalizeButtonLoading}
              caption={
                <div className="flex gap-2 items-center">
                  <div className="w-6">
                    <CheckMark />
                  </div>
                  <MSText textColor="text-white" fontWeight="text-bold" title="Finalize" />
                </div>
              }
              fontWeight="text-bold"
              type="small"
            />
          </div>
        </div>
      </div>
      <InvoicePayment
        openDynamicModal={openPaymentDetailModal}
        closeDynamicModal={() => setOpenPaymentDetailModal(false)}
        isTemporary={isTemporary}
      />

      <Snackbar
        open={isSnackBarOpen}
        handleClose={closeSnackbar}
        sx={{ bgcolor: MUI_COLORS.LIGHT_GREEN, border: '1px solid', borderColor: MUI_COLORS.GREEN }}
        title={
          <div className="flex">
            <div className="flex items-center">
              <SnackBarImage className="w-5 h-5" src={Success} />
            </div>
            <div className="ml-4">
              <XSText fontWeight="text-bold" textColor="text-gray-500" title={'Success'}></XSText>
              <MSText textColor="text-gray-500" title={'Successfully Saved'}></MSText>
            </div>
          </div>
        }
      />
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    getInvoiceDetails: state?.invoiceDetails?.invoiceData
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    invoiceData: (invoiceData) => dispatch(userActions.invoiceData(invoiceData)),
    createInvoice: (invoiceData) => dispatch(userActions.createInvoice(invoiceData)),
    editInvoice: (invoicedata) => dispatch(userActions.editInvoice(invoicedata)),
    invoiceCount: (invoiceId) => dispatch(userActions.invoiceCount(invoiceId)),
    getCurrencyPreference: (payload) => dispatch(profileActions.getCurrencyPreference(payload)),
    currencyPreference: (payload) => dispatch(profileActions.currencyPreference(payload)),
    getBankDetailsList: () => dispatch(paymentActions.getBankDetailsList())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceDetails);

InvoiceDetails.propTypes = {
  prevStep: PropTypes.func,
  getInvoiceDetails: PropTypes.object,
  invoiceData: PropTypes.func,
  closeDynamicModal: PropTypes.func,
  type: PropTypes.func,
  invoiceCount: PropTypes.func,
  getCurrencyPreference: PropTypes.func,
  currencyPreference: PropTypes.func,
  creteTempInvoice: PropTypes.func,
  showGoodAndServices: PropTypes.bool
};
InvoiceDetails.defaultProps = {
  showGoodAndServices: true
};

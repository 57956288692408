import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import MSText from '../ui/typography/MSText';
import PrimaryButton from '../ui/buttons/PrimaryButton';
import XXSText from '../ui/typography/XXSText';
import XSText from '../ui/typography/XSText';
import CloseIcon from '../../images/icons/close-icon.svg';
import '../../styles/pages/upload-image.scss';
import FileIcon from '../../images/icons/file-icon.svg';
import MDSubtitleText from '../ui/typography/MDSubtitleText';
import * as commonReduxActions from '../commonReduxStore/reduxStore/action';
import DynamicModal from '../ui/modal/DynamicModal';
import { navigate } from 'gatsby';
import { connect } from 'react-redux';
import { API_CODE, FILE_EXTENTION_TYPE, FILE_UPLOAD_TYPE, MUI_COLORS } from '../../constants/enum';
import { PATH_PAGE } from '../../routes/paths';
import { Document, Page, pdfjs } from 'react-pdf';
import * as importedInvoiceReducer from './reduxStore/action';
import PropTypes from 'prop-types';
import Snackbar from '../../components/Snackbar';
import { getFileExtension } from '../../helper/helpers';
function ImportedInvoiceFileUpload({ openDynamicModal, closeDynamicModal, uploadFile, uploadedInvoices }) {
  const [uploadLogo, setUploadLogo] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [saveImage, setSaveImage] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const [isButtonLoader, setIsButtonLoader] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [open, setOpen] = useState(false);

  const [successMessage, setSuccessMessage] = useState({
    title: '',
    content: ''
  });

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseModal = () => {
    closeDynamicModal();
    setSelectedFiles('');
  };

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const fileSelection = (acceptedFiles) => {
    if (acceptedFiles.length === 1) {
      let selectedFile = acceptedFiles?.map((file) =>
        getFileExtension(file?.name) === FILE_EXTENTION_TYPE.PNG ||
        getFileExtension(file?.name) === FILE_EXTENTION_TYPE.JPEG ||
        getFileExtension(file?.name) === FILE_EXTENTION_TYPE.JPG ||
        getFileExtension(file?.name) === FILE_EXTENTION_TYPE.PDF
          ? Object.assign(file, { preview: URL.createObjectURL(file) })
          : false
      );
      if (selectedFile[0] === false) {
        setSaveImage(false);
        setSelectedFiles([]);
        setUploadError(true);
      } else {
        setSelectedFiles(selectedFile);
        setSaveImage(true);
        setUploadError(false);
      }
    } else {
      setSuccessMessage({
        ...successMessage,
        title: 'Warning',
        content: `You cannot upload multiple files`
      });
      setOpen(true);
    }
  };

  const uploadFileHandler = async () => {
    setIsButtonLoader(true);
    const formData = new FormData();
    formData.append('file', selectedFiles[0]);
    const uploadedImage = await uploadFile(formData);
    if (uploadedImage?.status === API_CODE.STATUS_200) {
      uploadFileToGetExtractedData(formData, uploadedImage?.data?.data?.key);
    } else {
      setUploadError(true);
      setIsButtonLoader(false);
    }
  };

  const uploadFileToGetExtractedData = async (formData, documentCode) => {
    setIsButtonLoader(true);
    const uploadedFileResponse = await uploadedInvoices({ code: documentCode });
    if (uploadedFileResponse?.status === API_CODE.STATUS_200) {
      setIsButtonLoader(false);
      navigate(PATH_PAGE.createImportingInvoice, {
        state: { documentCode: documentCode }
      });
    } else {
      setIsButtonLoader(false);
    }
  };

  function formatBytes(bytes, decimals = 2) {
    if (!+bytes) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }

  const handleSelectingFiles = (event) => {
    if (selectedFiles.length > 0) {
      event.stopPropagation();
    }
  };

  const handleUploadFiles = () => {
    if (selectedFiles.length > 0) {
      uploadFileHandler();
    } else {
      const fileInput = document.getElementById('fileInput');
      fileInput.click();
    }
  };

  const handleAddInvoices = () => {
    setSelectedFiles('');
  };

  const handleRemoveFile = (file) => {
    const remainingFiles = selectedFiles.filter((item) => item.name !== file.name);
    setSelectedFiles(remainingFiles);
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <DynamicModal openDynamicModal={openDynamicModal} closeDynamicModal={() => handleCloseModal()} maxWidth="520px">
      <MDSubtitleText
        className="py-6"
        fontWeight="text-bold"
        textAlign="text-center"
        textColor="text-gray-500"
        title="Upload Invoice"
      />

      <div
        onClick={() => !saveImage && !uploadLogo && setUploadLogo(!uploadLogo)}
        className={`${!saveImage && !uploadLogo && 'cursor-pointer'}  ${uploadLogo ? ' items-start' : ' items-center'}`}
      >
        <Dropzone multiple={false} onDrop={(acceptedFiles) => fileSelection(acceptedFiles)}>
          {({ getRootProps, getInputProps }) => (
            <section className="container">
              <div {...getRootProps({ onClick: (event) => handleSelectingFiles(event) })}>
                <input {...getInputProps()} id="fileInput" />
                {selectedFiles && Array.isArray(selectedFiles) && selectedFiles.length > 0 ? (
                  <div className="selected-file">
                    <div>
                      {selectedFiles?.map((file, index) => (
                        <>
                          {file?.type === FILE_UPLOAD_TYPE.PNG ||
                          file?.type === FILE_UPLOAD_TYPE.JPEG ||
                          file?.type === FILE_UPLOAD_TYPE.JPG ||
                          file?.type === FILE_UPLOAD_TYPE.PDF ? (
                            <div
                              key={index + 1}
                              className="flex justify-between items-center mb-2 importing-invoice-upload-files"
                            >
                              {file.type === FILE_UPLOAD_TYPE.PDF ? (
                                <>
                                  <div className="flex items-center">
                                    <Document file={file.preview} onLoadSuccess={onDocumentLoadSuccess}>
                                      <Page pageNumber={1} width={70} height={100} />
                                    </Document>
                                    <div className="ml-6">
                                      <MSText fontWeight="text-bold" textColor="text-blue-100" title={file?.name} />
                                      <MSText
                                        textColor="text-gray-700"
                                        fontWeight="text-normal"
                                        title={formatBytes(file?.size)}
                                      />
                                    </div>
                                  </div>
                                  <img
                                    className="cursor-pointer"
                                    src={CloseIcon}
                                    alt="close-icon"
                                    onClick={() => handleRemoveFile(file)}
                                  />
                                </>
                              ) : file.type === FILE_UPLOAD_TYPE.PNG ||
                                file.type === FILE_UPLOAD_TYPE.JPEG ||
                                file?.type === FILE_UPLOAD_TYPE.JPG ? (
                                <>
                                  <div className="flex items-center h-28">
                                    <img
                                      className="mr-5 my-6 importing-invoice-image"
                                      src={file?.preview}
                                      alt="file-icon"
                                      width="100"
                                    />
                                    <div className="ml-6">
                                      <MSText fontWeight="text-bold" textColor="text-blue-100" title={file?.name} />
                                      <MSText
                                        textColor="text-gray-700"
                                        fontWeight="text-normal"
                                        title={formatBytes(file?.size)}
                                      />
                                    </div>
                                  </div>
                                  <img
                                    className="cursor-pointer"
                                    src={CloseIcon}
                                    alt="close-icon"
                                    onClick={() => handleRemoveFile(file)}
                                  />
                                </>
                              ) : file.type === FILE_UPLOAD_TYPE.MSWORD ||
                                file.type === FILE_UPLOAD_TYPE.OFFICE_DOCUMENT ? (
                                <>
                                  <div className="flex items-center h-28">
                                    <div>
                                      <img src={FileIcon} alt="doc type file" />
                                    </div>
                                    <div className="ml-6">
                                      <MSText fontWeight="text-bold" textColor="text-blue-100" title={file?.name} />
                                      <MSText
                                        textColor="text-gray-700"
                                        fontWeight="text-normal"
                                        title={formatBytes(file?.size)}
                                      />
                                    </div>
                                  </div>
                                  <img
                                    className="cursor-pointer"
                                    src={CloseIcon}
                                    alt="close-icon"
                                    onClick={() => handleRemoveFile(file)}
                                  />
                                </>
                              ) : (
                                ''
                              )}
                            </div>
                          ) : (
                            <div className=" flex flex-col justify-center items-center importing-invoice-drop-container bg-customBg-mainBg">
                              <div className="mb-2">
                                <XXSText
                                  fontWeight="text-bold"
                                  className="whitespace-pre-line"
                                  textAlign="text-center"
                                  textColor="text-blue-100"
                                  title={`Click to upload or drag and drop`}
                                />
                                <MSText
                                  className="whitespace-pre-line"
                                  textAlign="text-center"
                                  textColor="text-coral-500"
                                  title={`PDF, JPG, and PNG`}
                                />
                              </div>
                            </div>
                          )}
                        </>
                      ))}
                    </div>
                  </div>
                ) : (
                  <div className=" flex flex-col justify-center items-center importing-invoice-drop-container bg-customBg-mainBg">
                    <div className="mb-2">
                      <XXSText
                        fontWeight="text-bold"
                        className="whitespace-pre-line"
                        textAlign="text-center"
                        textColor="text-blue-100"
                        title={`Click to upload or drag and drop`}
                      />
                      <MSText
                        className="whitespace-pre-line"
                        textAlign="text-center"
                        textColor="text-gray-450"
                        title={`PDF, JPG, and PNG`}
                      />
                    </div>
                  </div>
                )}
              </div>
              {uploadError ? (
                <MSText
                  className="mt-2"
                  textColor="text-coral-500"
                  title="Only PDF, JPG, and PNG files with max size of 1 MB."
                />
              ) : null}
            </section>
          )}
        </Dropzone>
      </div>
      <div className="flex items-center justify-center w-full mt-8">
        {selectedFiles.length > 0 ? (
          <div className="flex-grow">
            <PrimaryButton
              id="imported-invoice-add-invoice-btn"
              fontWeight="text-bold"
              className="imported-upload-modal-button w-full"
              color="text-blue-300"
              bgColor="bg-white"
              caption="Add Invoice"
              onClick={handleAddInvoices}
            />
          </div>
        ) : (
          <div className="flex-grow">
            <PrimaryButton
              id="imported-invoice-close-btn"
              fontWeight="text-bold"
              className="imported-upload-modal-button w-full"
              color="text-blue-300"
              bgColor="bg-white"
              caption="Close"
              onClick={handleCloseModal}
            />
          </div>
        )}
        <div className="flex-grow ml-3">
          <PrimaryButton
            id="imported-invoice-upload-btn"
            isLoading={isButtonLoader}
            fontWeight="text-bold"
            className="imported-upload-modal-button w-full"
            onClick={handleUploadFiles}
            caption="Upload"
          />
        </div>
      </div>
      <Snackbar
        open={open}
        handleClose={handleClose}
        sx={{ bgcolor: MUI_COLORS.LIGHT_YELLOW, border: '1px solid', borderColor: MUI_COLORS.SUNGLOW }}
        title={
          <div>
            <div className="ml-4">
              <XSText fontWeight="text-bold" textColor="text-gray-500" title={successMessage.title}></XSText>
              <MSText textColor="text-gray-500" title={successMessage.content}></MSText>
            </div>
          </div>
        }
      ></Snackbar>
    </DynamicModal>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    uploadFile: (uploadedImage) => dispatch(commonReduxActions.uploadDocument(uploadedImage)),
    uploadedInvoices: (uploadedFile) => dispatch(importedInvoiceReducer.uploadedInvoices(uploadedFile))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ImportedInvoiceFileUpload);

ImportedInvoiceFileUpload.propTypes = {
  openDynamicModal: PropTypes.bool,
  closeDynamicModal: PropTypes.any,
  uploadFile: PropTypes.func,
  uploadedInvoices: PropTypes.func
};

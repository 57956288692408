import * as actionTypes from './actionTypes';

let initialState = {
  uploadedFile: {},
  checkSendEmail: {},
  checkValidateSendEmail: {},
  loading: false,
  error: false,
  previewedDocument: {},
  uploadedDocument: {},
  getCountryDetails: {},
  documentSignedUrl: {},
  publicDocumentUploadPayload: {}
};

const CommonReduxStore = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FILE_UPLOAD_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.FILE_UPLOAD_REQUEST_SUCCESS:
      return {
        ...state,
        uploadedFile: action.uploadedFile,
        loading: false,
        error: false
      };
    case actionTypes.FILE_UPLOAD_REQUEST_FAILURE:
      return { ...state, error: action.error, loading: false };

    case actionTypes.CHECK_SEND_EMAIL_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.CHECK_SEND_EMAIL_REQUEST_SUCCESS:
      return {
        ...state,
        checkSendEmail: action.checkSendEmail,
        loading: false,
        error: false
      };
    case actionTypes.CHECK_SEND_EMAIL_REQUEST_FAILURE:
      return { ...state, error: action.error, loading: false };

    case actionTypes.CHECK_VALIDATE_SEND_EMAIL_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.CHECK_VALIDATE_SEND_EMAIL_REQUEST_SUCCESS:
      return {
        ...state,
        checkValidateSendEmail: action.checkValidateSendEmail,
        loading: false,
        error: false
      };
    case actionTypes.CHECK_SEND_EMAIL_REQUEST_FAILURE:
      return { ...state, error: action.error, loading: false };

    case actionTypes.GET_COUNTRY_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.GET_COUNTRY_REQUEST_SUCCESS:
      return {
        ...state,
        getCountryDetails: action.getCountryPayload,
        loading: false,
        error: false
      };
    case actionTypes.GET_COUNTRY_REQUEST_FAILURE:
      return { ...state, error: action.error, loading: false };

    case actionTypes.PREVIEW_DOCUMENT_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.PREVIEW_DOCUMENT_REQUEST_SUCCESS:
      return {
        ...state,
        previewedDocument: action.previewedDocument,
        loading: false,
        error: false
      };
    case actionTypes.PREVIEW_DOCUMENT_REQUEST_FAILURE:
      return { ...state, error: action.error, loading: false };

    case actionTypes.PDF_SIGNED_URL_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.PDF_SIGNED_URL_REQUEST_SUCCESS:
      return {
        ...state,
        documentSignedUrl: action.documentSignedUrl,
        loading: false,
        error: false
      };
    case actionTypes.PDF_SIGNED_URL_REQUEST_FAILURE:
      return { ...state, error: action.error, loading: false };

    case actionTypes.UPLOAD_DOCUMENT_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.UPLOAD_DOCUMENT_REQUEST_SUCCESS:
      return {
        ...state,
        uploadedDocument: action.uploadedDocument,
        loading: false,
        error: false
      };
    case actionTypes.PUBLIC_DOCUMENT_UPLOAD_REQUEST_FAILURE:
      return { ...state, error: action.error, loading: false };

    case actionTypes.PUBLIC_DOCUMENT_UPLOAD_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.PUBLIC_DOCUMENT_UPLOAD_REQUEST_SUCCESS:
      return {
        ...state,
        publicDocumentUploadPayload: action.publicDocumentUploadPayload,
        loading: false,
        error: false
      };
    case actionTypes.PUBLIC_DOCUMENT_UPLOAD_REQUEST_FAILURE:
      return { ...state, error: action.error, loading: false };

    default:
      return state;
  }
};
export default CommonReduxStore;

import * as React from 'react';
import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { Checkbox, TableRow, TableCell, Typography, Stack, Link, MenuItem } from '@mui/material';
// utils
// components
import Label from '../../../components/Label';
import Iconify from '../../../components/Iconify';
import { TableMoreMenu } from '../../../components/table';
import { CONTACT_TABLE_TITLE, MUI_COLORS, REGEX } from '../../../constants/enum';
import Avatar from '../../../components/ui/Avatar';

// ----------------------------------------------------------------------

ContactTableRow.propTypes = {
  row: PropTypes.object.isRequired,
  selected: PropTypes.bool,
  onSelectRow: PropTypes.func,
  onViewRow: PropTypes.func,
  onEditRow: PropTypes.func,
  onDeleteRow: PropTypes.func
};

export default function ContactTableRow({ row, selected, onSelectRow, onViewRow, onDeleteRow, onEditRow }) {
  const theme = useTheme();

  const { country, firstName, lastName, companyName, profileImagePublic, invoiceCount, contactType, amount } = row;

  const [openMenu, setOpenMenuActions] = useState(null);

  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  const amountWithoutCurrency = Number(amount?.replace(REGEX.GET_NUMBER_WITH_DECIMAL, ''));
  const currencySymbol = amount?.replace(REGEX.GET_VALUE_WITHOUT_DECIMAL_INT, '');

  return (
    <TableRow hover selected={selected}>
      {/* <TableCell padding="checkbox">
        <Checkbox checked={selected} onClick={onSelectRow} />
      </TableCell> */}
      <TableCell sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={onViewRow}>
        <div className="mr-2">
          <Avatar
            avatarImage={profileImagePublic}
            avatarName={firstName ? firstName?.charAt(0) : companyName?.charAt(0)}
          />
        </div>
        <Stack>
          <Typography
            sx={{ color: MUI_COLORS.MID_NIGHT, textDecoration: 'none', boxShadow: 'none' }}
            variant="subtitle2"
            noWrap
          >
            {firstName ? `${firstName} ${lastName}` : companyName}
          </Typography>
          <Link noWrap variant="body2" onClick={onViewRow} sx={{ color: 'text.disabled', cursor: 'pointer' }}>
            {firstName && companyName}
          </Link>
        </Stack>
      </TableCell>
      <TableCell align="left" onClick={onViewRow} sx={{ cursor: 'pointer' }}>
        {contactType === CONTACT_TABLE_TITLE.ONLY_CLIENTS ? 'Client' : 'Supplier'}
      </TableCell>
      <TableCell align="left" onClick={onViewRow} sx={{ cursor: 'pointer' }}>
        {country}
      </TableCell>
      <TableCell align="left" onClick={onViewRow} sx={{ textTransform: 'capitalize', cursor: 'pointer' }}>
        {invoiceCount}
      </TableCell>

      <TableCell onClick={onViewRow} sx={{ cursor: 'pointer' }} align="left">
        {currencySymbol + amountWithoutCurrency?.toFixed(2)}
      </TableCell>
      <TableCell align="right">
        <TableMoreMenu
          open={openMenu}
          onOpen={handleOpenMenu}
          onClose={handleCloseMenu}
          actions={
            <>
              <MenuItem
                className="pl-6"
                onClick={() => {
                  onViewRow();
                  handleCloseMenu();
                }}
              >
                <Iconify icon={'eva:eye-fill'} />
                View
              </MenuItem>
              <MenuItem
                onClick={() => {
                  onEditRow();
                  handleCloseMenu();
                }}
              >
                <Iconify icon={'eva:edit-fill'} />
                Edit
              </MenuItem>
              <MenuItem
                className="pl-6"
                onClick={() => {
                  onDeleteRow();
                  handleCloseMenu();
                }}
                sx={{ color: 'error.main' }}
              >
                <Iconify icon={'eva:trash-2-outline'} />
                Delete
              </MenuItem>
            </>
          }
        />
      </TableCell>
    </TableRow>
  );
}

import React, { useState, useEffect } from 'react';
import TextInput from '../ui/inputs/TextInput';
import { useForm } from 'react-hook-form';
import { API_CODE, INVOICE_STATUS } from '../../constants/enum';
import { invoiceInputBackgroundColor } from '../../helper/helpers';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SelectCountryAutoComplete from '../common/SelectCountryAutoComplete';
import MDSubtitleText from '../ui/typography/MDSubtitleText';
import StatrysLoader from '../ui/loaders/StatrysLoader';
import BaseText from '../ui/typography/BaseText';
import SelectInputAutoComplete from '../ui/inputs/selectInputAutoComplete';
import InvoiceCurrencies from '../../data/invoice-currencies-list.json';
import { navigate } from 'gatsby';
import { PATH_PAGE } from '../../routes/paths';
import { useLocation } from '@reach/router';
import * as importingInvoiceReducer from './reduxStore/action';
import { getUTCBasedDate } from '../../utils/formatTime';
import ImportedInvoiceFileUpload from './ImportedInvoiceFileUpload';
import * as userProfileActions from '../../components/profile/reduxStore/action';
import ValidationMessage from '../ui/ValidationMessage';

const allSupplierList = [
  {
    firstName: 'Raj',
    lastName: 'Kapoor'
  }
];

function ImportedInvoiceFrom({ getImportedInvoice, editImportedInvoice, getUserProfile }) {
  const [selectedCountry, setSelectedCountry] = useState('');
  const [invoiceSupplierData, setInvoiceSupplierData] = useState('');
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [userProfileData, setUserProfileData] = useState('');
  const { register, handleSubmit, watch, errors } = useForm();
  const [isDueDate, setIsDueDate] = useState(false);
  const [creatDate, setCreatDate] = useState('');

  const location = useLocation();
  const params = new URLSearchParams(location?.search);
  const invoiceId = params.get('id');

  const onSelectingCountry = (country) => {
    setSelectedCountry(country?.name);
  };

  const getProfileDetailsHandler = async () => {
    const getUserProfileDetail = await getUserProfile();
    if (getUserProfileDetail?.status === API_CODE?.STATUS_200) {
      setUserProfileData(getUserProfileDetail?.data?.data);
    }
  };

  const findElementByCurrencyName = (currencyName) => {
    const getCurrency = InvoiceCurrencies?.filter((curr) => curr?.name === currencyName);
    return getCurrency[0];
  };

  const onSubmitSupplierInvoice = async (data) => {
    setCreatDate(data?.createDate);
    if (compareDates(data?.dueDate, data?.createDate)) {
      editImportedInvoiceHandler(data);
    }
  };

  const handleDueDate = (event) => {
    compareDates(event.target.value, creatDate);
  };

  function compareDates(date1, date2) {
    const convertedDate1 = new Date(date1);
    const convertedDate2 = new Date(date2);
    const isFutureDueDate = convertedDate1 >= convertedDate2;
    if (isFutureDueDate) {
      setIsDueDate(false);
      return true;
    } else {
      setIsDueDate(true);
      return false;
    }
  }

  const editImportedInvoiceHandler = async (data) => {
    const importedInvoicePayload = {
      invoiceId: invoiceId,
      invoiceNumber: data?.invoiceNumber,
      totalAmount: data?.invoiceAmount,
      taxWithPrice: [{ taxPrice: data?.taxes }],
      createDate: data?.createDate,
      uploadedBy: userProfileData?.firstName
        ? `${userProfileData?.firstName} ${userProfileData?.lastName}`
        : userProfileData?.email,
      dueDate: data?.dueDate,
      pdfURL: invoiceSupplierData.pdfSignedUrl,
      currency: selectedCurrency ? selectedCurrency : findElementByCurrencyName(invoiceSupplierData?.locale?.currency),
      paymentDetails: {
        iBan: data?.iban,
        swift: data?.swift,
        bankName: data?.bankName,
        accountNumber: data?.accountNumber,
        routingCode: data?.routingCode
      },
      supplier: {
        supplierId: invoiceSupplierData?.supplier?._id,
        companyName: data?.companyName,
        registrationNumber: data?.registrationNumber,
        state: data?.state,
        website: data?.website,
        email: data?.email,
        address: data?.address,
        city: data?.city,
        country: selectedCountry ? selectedCountry : invoiceSupplierData?.supplier?.country,
        postalCode: data?.postalCode
      }
    };
    const editImportedInvoicesResponse = await editImportedInvoice(importedInvoicePayload);
    if (editImportedInvoicesResponse?.status === API_CODE.STATUS_200) {
      navigate(PATH_PAGE.viewIncomingInvoices + `?id=${invoiceId}`);
    }
  };

  const getImportingInvoiceDetails = async (importedInvoiceId) => {
    const getImportedInvoicesResponse = await getImportedInvoice(importedInvoiceId);
    if (getImportedInvoicesResponse?.status === API_CODE.STATUS_200) {
      setInvoiceSupplierData(getImportedInvoicesResponse?.data?.data);
      setCreatDate(getImportedInvoicesResponse?.data?.data?.createDate);
    }
  };

  useEffect(() => {
    getImportingInvoiceDetails(invoiceId);
    getProfileDetailsHandler();
  }, []);

  const invoiceListWithFullName = allSupplierList?.map((invoiceDetails) => ({
    ...invoiceDetails,
    name: invoiceDetails?.firstName + ' ' + invoiceDetails?.lastName
  }));
  return (
    <>
      {!invoiceSupplierData ? (
        <div className="h-full flex items-center">
          <StatrysLoader />
        </div>
      ) : (
        <>
          <div className="flex">
            <div className="w-2/5">
              <ImportedInvoiceFileUpload pdfURL={invoiceSupplierData?.pdfSignedURL} />
            </div>
            <div className="imported-invoice-form-container bg-white md:ml-6 pb-8 w-3/5">
              <div className="mx-8">
                <MDSubtitleText className="py-6" fontWeight="text-bold" textColor="text-gray-500" title="New Invoice" />
                <form id="edit-imported-invoice-form" onSubmit={handleSubmit(onSubmitSupplierInvoice)}>
                  <div className="flex flex-col gap-4 w-full mb-6">
                    <div className="md:w-full flex md:flex-row flex-col items-center gap-4">
                      <TextInput
                        textFieldContainerClass="w-full"
                        defaultValue={invoiceSupplierData?.invoiceNumber}
                        backgroundColor={invoiceInputBackgroundColor(invoiceSupplierData?.invoiceNumber)}
                        name="invoiceNumber"
                        label="Invoice Number"
                        inputRef={register({
                          required: false
                        })}
                        error={errors?.invoiceNumber ? true : false}
                      />
                      <TextInput
                        name="createDate"
                        textFieldContainerClass="w-full"
                        defaultValue={
                          invoiceSupplierData?.createDate &&
                          getUTCBasedDate(new Date(invoiceSupplierData?.createDate), 'UTC')
                        }
                        backgroundColor={invoiceInputBackgroundColor(invoiceSupplierData?.createDate)}
                        label="Created Date"
                        InputLabelProps={{
                          shrink: true
                        }}
                        onChange={(e) => setCreatDate(e.target.value)}
                        error={errors?.invoiceNumber ? true : false}
                        type="date"
                        inputRef={register({
                          required: true
                        })}
                      />
                    </div>

                    <div className="md:w-full flex md:flex-row flex-col items-center gap-4 items-baseline">
                      <div className="w-full">
                        <SelectInputAutoComplete
                          options={InvoiceCurrencies}
                          defaultValue={invoiceSupplierData?.currency}
                          backgroundColor={invoiceInputBackgroundColor(invoiceSupplierData?.currency)}
                          label="Currency"
                          setSelectedValue={setSelectedCurrency}
                          name="currency"
                        />
                      </div>
                      <TextInput
                        textFieldContainerClass="w-full"
                        defaultValue={
                          invoiceSupplierData?.dueDate && getUTCBasedDate(new Date(invoiceSupplierData?.dueDate), 'UTC')
                        }
                        backgroundColor={invoiceInputBackgroundColor(invoiceSupplierData?.dueDate)}
                        name="dueDate"
                        label="Due Date"
                        type="date"
                        onChange={(e) => handleDueDate(e)}
                        InputLabelProps={{
                          shrink: true
                        }}
                        inputRef={register({
                          required: false
                        })}
                        error={errors?.dueDate || isDueDate ? true : false}
                        helperText={
                          (errors?.dueDate && <ValidationMessage title={'Please select a due date.'} />,
                          isDueDate && <ValidationMessage title={'Due Date should not be less than Created Date'} />)
                        }
                      />
                    </div>
                  </div>
                  <BaseText
                    textColor="text-gray-600"
                    fontWeight="text-bold"
                    fontSize="text-lg"
                    title="Issuing company information"
                    className="mb-4"
                  />

                  <TextInput
                    defaultValue={invoiceSupplierData?.supplier?.companyName}
                    backgroundColor={invoiceInputBackgroundColor(invoiceSupplierData?.supplier?.companyName)}
                    margin="w-full"
                    name="companyName"
                    label="Company Name"
                    inputRef={register({
                      required: false
                    })}
                  />
                  <TextInput
                    defaultValue={invoiceSupplierData?.supplier?.registrationNumber}
                    backgroundColor={invoiceInputBackgroundColor(invoiceSupplierData?.supplier?.registrationNumber)}
                    margin="mt-4 "
                    name="registrationNumber"
                    label="Registration number"
                    inputRef={register({
                      required: false
                    })}
                  />

                  <div className="flex md:flex-row flex-col md:gap-4 w-full">
                    <TextInput
                      defaultValue={invoiceSupplierData?.supplier?.email}
                      backgroundColor={invoiceInputBackgroundColor(invoiceSupplierData?.supplier?.email)}
                      margin="mt-4 w-full"
                      name="email"
                      label="Email Address"
                      inputRef={register({
                        required: false
                      })}
                      error={errors.email ? true : false}
                    />
                    <TextInput
                      defaultValue={invoiceSupplierData?.supplier?.website}
                      backgroundColor={invoiceInputBackgroundColor(invoiceSupplierData?.supplier?.website)}
                      margin="mt-4 w-full"
                      name="website"
                      label="Website"
                      inputRef={register({
                        required: false
                      })}
                    />
                  </div>

                  <div className="flex md:flex-row flex-col md:gap-4 w-full">
                    <TextInput
                      defaultValue={invoiceSupplierData?.supplier?.address}
                      backgroundColor={invoiceInputBackgroundColor(invoiceSupplierData?.supplier?.address)}
                      margin="mt-4 w-full"
                      name="address"
                      label="Address"
                      inputRef={register({
                        required: false
                      })}
                    />
                    <TextInput
                      defaultValue={invoiceSupplierData?.supplier?.city}
                      backgroundColor={invoiceInputBackgroundColor(invoiceSupplierData?.supplier?.city)}
                      margin="mt-4 w-full"
                      name="city"
                      label="City"
                      inputRef={register({
                        required: false
                      })}
                    />
                  </div>
                  <div className="flex md:flex-row flex-col md:gap-4 w-full mb-4">
                    <TextInput
                      defaultValue={invoiceSupplierData?.supplier?.state}
                      backgroundColor={invoiceInputBackgroundColor(invoiceSupplierData?.supplier?.state)}
                      margin="mt-4 w-full"
                      name="state"
                      label="State"
                      inputRef={register({
                        required: false
                      })}
                    />
                    <TextInput
                      defaultValue={invoiceSupplierData?.supplier?.postalCode}
                      backgroundColor={invoiceInputBackgroundColor(invoiceSupplierData?.supplier?.postalCode)}
                      margin="mt-4 w-full"
                      name="postalCode"
                      label="Zip / Postal Code"
                      inputRef={register({
                        required: false
                      })}
                    />
                    <div className="mt-4 w-full">
                      <SelectCountryAutoComplete
                        defaultValue={invoiceSupplierData?.supplier?.country}
                        setSelectedValue={onSelectingCountry}
                        label="Country"
                        name="country"
                      />
                    </div>
                  </div>
                  <BaseText
                    textColor="text-gray-600"
                    fontWeight="text-bold"
                    fontSize="text-lg"
                    title="Payment information"
                    className="mb-4"
                  />
                  <TextInput
                    textFieldContainerClass="w-full mb-4"
                    defaultValue={invoiceSupplierData?.totalAmount}
                    backgroundColor={invoiceInputBackgroundColor(invoiceSupplierData?.totalAmount)}
                    name="invoiceAmount"
                    label="Invoice Amount"
                    inputRef={register({
                      required: false
                    })}
                    error={errors?.invoiceNumber ? true : false}
                  />
                  <TextInput
                    textFieldContainerClass="w-full mb-8"
                    defaultValue={invoiceSupplierData?.taxWithPrice?.[0]?.taxPrice}
                    backgroundColor={invoiceInputBackgroundColor(invoiceSupplierData?.taxWithPrice?.[0]?.taxPrice)}
                    name="taxes"
                    label="Taxes"
                    inputRef={register({
                      required: false
                    })}
                    error={errors?.invoiceNumber ? true : false}
                  />
                  <BaseText
                    textColor="text-gray-600"
                    fontWeight="text-bold"
                    fontSize="text-lg"
                    title="Banking details"
                    className="mb-4"
                  />
                  {!invoiceSupplierData?.paymentDetails?.iBan && (
                    <TextInput
                      textFieldContainerClass="w-full mb-4"
                      defaultValue={invoiceSupplierData?.paymentDetails?.bankName}
                      backgroundColor={invoiceInputBackgroundColor(invoiceSupplierData?.paymentDetails?.bankName)}
                      name="bankName"
                      label="Bank name"
                      inputRef={register({
                        required: false
                      })}
                      error={errors?.invoiceNumber ? true : false}
                    />
                  )}
                  <div className="flex md:flex-row flex-col md:gap-4 w-full mb-4">
                    {!invoiceSupplierData?.paymentDetails?.iBan && (
                      <TextInput
                        textFieldContainerClass="w-full"
                        defaultValue={invoiceSupplierData?.paymentDetails?.accountNumber}
                        backgroundColor={invoiceInputBackgroundColor(
                          invoiceSupplierData?.paymentDetails?.accountNumber
                        )}
                        name="accountNumber"
                        label="Account Number"
                        inputRef={register({
                          required: false
                        })}
                        error={errors?.invoiceNumber ? true : false}
                      />
                    )}
                    {invoiceSupplierData?.paymentDetails?.iBan && (
                      <TextInput
                        textFieldContainerClass="w-full"
                        defaultValue={invoiceSupplierData?.paymentDetails?.iBan}
                        backgroundColor={invoiceInputBackgroundColor(invoiceSupplierData?.paymentDetails?.iBan)}
                        name="iban"
                        label="IBAN"
                        inputRef={register({
                          required: false
                        })}
                        error={errors?.invoiceNumber ? true : false}
                      />
                    )}
                    <TextInput
                      textFieldContainerClass="w-full"
                      defaultValue={invoiceSupplierData?.paymentDetails?.swift}
                      backgroundColor={invoiceInputBackgroundColor(invoiceSupplierData?.paymentDetails?.swift)}
                      name="swift"
                      label="SWIFT/BIC"
                      inputRef={register({
                        required: false
                      })}
                      error={errors?.invoiceNumber ? true : false}
                    />
                  </div>
                  {!invoiceSupplierData?.paymentDetails?.iBan && (
                    <div className="imported-invoice-form-routing">
                      <TextInput
                        textFieldContainerClass="w-full"
                        defaultValue={invoiceSupplierData?.paymentDetails?.routingCode}
                        backgroundColor={invoiceInputBackgroundColor(invoiceSupplierData?.paymentDetails?.routingCode)}
                        name="routingCode"
                        label="Routing code"
                        inputRef={register({
                          required: false
                        })}
                        error={errors?.invoiceNumber ? true : false}
                      />
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    uploadedFileExtractedData: state?.importingInvoiceReducer?.importedInvoiceData?.data
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getImportedInvoice: (invoiceId) => dispatch(importingInvoiceReducer.getImportingInvoiceDetails(invoiceId)),
    editImportedInvoice: (invoicePayload) => dispatch(importingInvoiceReducer.editImportingInvoice(invoicePayload)),
    getUserProfile: () => dispatch(userProfileActions.getUserProfile())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ImportedInvoiceFrom);

ImportedInvoiceFrom.propTypes = {
  uploadedFileExtractedData: PropTypes.any,
  createImportedInvoices: PropTypes.func,
  getUserProfile: PropTypes.func
};

import React, { useState } from 'react';
import PrimaryButton from '../ui/buttons/PrimaryButton';
import MDSubtitleText from '../ui/typography/MDSubtitleText';
import BaseText from '../ui/typography/BaseText';
import AddPaymentModal from '../publicInvoice/AddPaymentModal';
import { API_CODE, INVOICE_STATUS, MUI_COLORS, PAYMENT_STATUS_INVOICE } from '../../constants/enum';
import XMSText from '../ui/typography/XMSText';
import {
  concatenateSelectedCurrency,
  convertDateToDDMMYYYY,
  findTotalCharacter,
  getFileName,
  stringTruncate,
  takeOutImageName
} from '../../helper/helpers';
import XSText from '../ui/typography/XSText';
import StatrysLoader from '../ui/loaders/StatrysLoader';
import PaymentMenu from './PaymentMenu';
import CashInHandIcon from '../../images/icons/cash-in-hand-icon.svg';
import Snackbar from '../Snackbar';
import Image from '../Image';
import Success from '../../images/icons/success-delete-icon.svg';
import PropTypes from 'prop-types';
import MSText from '../ui/typography/MSText';
import * as paymentActionRedux from '../invoicePaymentDetails/reduxStore/action';
import { connect } from 'react-redux';
import ApprovalConformationModal from '../invoiceCommon/ApprovalConfirmationModal';
import { Divider } from '@mui/material';
import VectorIcon from '../../images/icons/vector.svg';

function PaymentDetails({
  getInvoiceDetails,
  publicInvoiceDetails,
  isGetInvoiceDetailsLoading,
  handleInvoiceDetailsbyID,
  invoiceDeleteMarkPayment,
  updateStatusOnPayment
}) {
  const [addPayment, setAddPayment] = useState(false);
  const [paidPayment, setPaidPayment] = useState(false);
  const [successMessage, setSuccessMessage] = useState({
    title: '',
    content: ''
  });
  const [open, setOpen] = useState(false);
  const [approvePayment, setApprovePayment] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState('');

  const closePaymentModal = () => {
    setApprovePayment(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const closePaidPaymentModal = () => {
    setPaidPayment(false);
  };

  const openPaidPaymentModal = () => {
    setPaidPayment(true);
  };

  const closeAddPaymentModal = () => {
    setAddPayment(false);
  };

  const handleToAddPayment = () => {
    setAddPayment(true);
  };

  const onApprovePaymentHandler = (payment) => {
    setPaymentDetails(payment);
    setApprovePayment(true);
  };

  const onDeleteMarkAsPaid = (payment) => {
    const queryParams = {
      params: { invoiceId: getInvoiceDetails?._id, paymentId: payment?._id }
    };
    deleteMarkPaymentHandler(queryParams, getInvoiceDetails?.invoiceNumber);
  };

  const deleteMarkPaymentHandler = async (queryParams, invoiceNumber) => {
    const invoiceDeletePaymentResponse = await invoiceDeleteMarkPayment(queryParams);
    if (invoiceDeletePaymentResponse?.status === API_CODE.STATUS_200) {
      handleInvoiceDetailsbyID();
      setSuccessMessage({
        ...successMessage,
        title: 'Success',
        content: `Invoice ${invoiceNumber} was successfully deleted!`
      });
      setOpen(true);
    }
  };

  const lastPaymentRecorded = getInvoiceDetails?.payments?.slice(-1)[0];

  return (
    <div className="bg-white whitespace-pre-line py-6 px-8 public-invoice-details-container">
      {isGetInvoiceDetailsLoading ? (
        <StatrysLoader />
      ) : (
        <>
          <MDSubtitleText fontWeight="text-bold" textColor="text-gray-500" title="Payments" />
          <BaseText
            className="mt-2"
            textColor={getInvoiceDetails?.payments?.length === 0 ? 'text-gray-500' : 'text-green-500'}
            fontSize={
              findTotalCharacter(getInvoiceDetails?.currency, getInvoiceDetails?.totalAmount) ? 'text-xl' : `text-3xl`
            }
            fontWeight="text-bold"
            title={`${concatenateSelectedCurrency(getInvoiceDetails?.currency, getInvoiceDetails?.totalAmount)}`}
          />
          <XSText
            textColor={
              getInvoiceDetails?.payments?.length > 0
                ? getInvoiceDetails?.paymentStatus === PAYMENT_STATUS_INVOICE.PAID
                  ? 'text-green-500'
                  : 'text-gray-450'
                : 'text-gray-700'
            }
            className="mb-10 mt-2"
            title={`${
              getInvoiceDetails?.payments?.length > 0
                ? getInvoiceDetails?.paymentStatus === PAYMENT_STATUS_INVOICE.PAID
                  ? `Paid in full on ${convertDateToDDMMYYYY(lastPaymentRecorded?.paidDate)}`
                  : `${concatenateSelectedCurrency(
                      getInvoiceDetails?.currency,
                      getInvoiceDetails?.pendingAmount
                    )} remaining by ${convertDateToDDMMYYYY(getInvoiceDetails?.dueDate)}`
                : `due on ${convertDateToDDMMYYYY(getInvoiceDetails?.dueDate)}`
            }`}
          />

          {getInvoiceDetails?.payments?.length > 0 && (
            <div className="mt-10">
              {getInvoiceDetails?.payments?.reverse().map((payment) => (
                <>
                  <div className="bg-customBg-mainBg pt-2 pb-4 rounded-xl mb-2 mt-2">
                    <div className="px-4">
                      <div className="flex justify-between items-center">
                        <XMSText
                          className="my-2"
                          title={payment?.paidDate && convertDateToDDMMYYYY(payment?.paidDate)}
                        />
                        <div className="cursor-pointer">
                          <PaymentMenu
                            isApproved={payment?.isApproved}
                            onApproveMarkAsPaid={() => onApprovePaymentHandler(payment)}
                            onDeleteMarkAsPaid={() => onDeleteMarkAsPaid(payment)}
                          />
                        </div>
                      </div>
                      <XSText
                        className="mb-2"
                        textColor="text-green-500"
                        fontWeight="text-bold"
                        title={
                          payment?.paidAmount &&
                          concatenateSelectedCurrency(getInvoiceDetails?.currency, payment?.paidAmount)
                        }
                      />
                      <div className="flex justify-between">
                        <XMSText title={payment?.paymentType} />
                        <XMSText title={!payment?.isApproved && 'Pending review'} />
                      </div>
                    </div>
                    {payment?.paymentProofSignedUrl && (
                      <>
                        <Divider className="border border-white w-full mt-4" />
                        <div className="mt-4 flex px-4">
                          <img src={VectorIcon} alt="file Name" />
                          <MSText
                            className="ml-3"
                            textColor="text-coral-500"
                            title={
                              <a href={payment?.paymentProofSignedUrl} target="_blank">
                                Payment Proof
                              </a>
                            }
                          />
                        </div>
                      </>
                    )}
                  </div>
                </>
              ))}
            </div>
          )}

          {(getInvoiceDetails?.payments?.length === 0 ||
            getInvoiceDetails?.paymentStatus === PAYMENT_STATUS_INVOICE.PARTIAL_PAID) && (
            <PrimaryButton
              id="payment-details-record-payment-btn"
              type="small"
              className="public-invoice-download-button mb-2 w-full mt-10"
              bgColor="bg-white"
              color="text-gray-300"
              fontSize="text-lg"
              fontWeight="text-bold"
              caption="Record payment"
              onClick={handleToAddPayment}
            />
          )}
          <AddPaymentModal
            isPublic={false}
            getInvoiceDetails={getInvoiceDetails}
            updateStatusOnPayment={updateStatusOnPayment}
            handleInvoiceDetailsbyID={handleInvoiceDetailsbyID}
            openDynamicModal={addPayment}
            closeDynamicModal={closeAddPaymentModal}
            openPaidPaymentModal={openPaidPaymentModal}
            publicInvoiceDetails={publicInvoiceDetails}
          />
          <ApprovalConformationModal
            getInvoiceDetails={getInvoiceDetails}
            getPaymentDetails={paymentDetails}
            openDynamicModal={approvePayment}
            closeDynamicModal={closePaymentModal}
            handleInvoiceDetailsbyID={handleInvoiceDetailsbyID}
          />
          <Snackbar
            open={open}
            handleClose={handleClose}
            sx={{
              bgcolor: MUI_COLORS.LIGHT_GREEN,
              border: '1px solid',
              borderColor: MUI_COLORS.GREEN,
              position: 'relative',
              zIndex: 'modal'
            }}
            title={
              <div className="flex">
                <div className="flex items-center">
                  <Image className="w-5 h-5" src={Success} />
                </div>
                <div className="ml-4">
                  <XSText fontWeight="text-bold" textColor="text-gray-500" title={successMessage.title}></XSText>
                  <MSText textColor="text-gray-500" title={successMessage.content}></MSText>
                </div>
              </div>
            }
          ></Snackbar>
        </>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {
    invoicePaymentApprove: (invoicedata) => dispatch(paymentActionRedux.invoicePaymentApprove(invoicedata)),
    invoiceDeleteMarkPayment: (invoicedata) => dispatch(paymentActionRedux.invoiceDeleteMarkPayment(invoicedata))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentDetails);

PaymentDetails.propTypes = {
  getInvoiceDetails: PropTypes.any,
  publicInvoiceDetails: PropTypes.any,
  isGetInvoiceDetailsLoading: PropTypes?.bool,
  invoicePaymentApprove: PropTypes.func,
  invoiceDeleteMarkPayment: PropTypes.func
};

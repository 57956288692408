import React from 'react';
import PrimaryButton from '../ui/buttons/PrimaryButton';
import MDSubtitleText from '../ui/typography/MDSubtitleText';
import BaseText from '../ui/typography/BaseText';
import { fDate, monthDateSuffix } from '../../utils/formatTime';
import { INVOICE_STATUS, PAYMENT_STATUS_INVOICE } from '../../constants/enum';
import { concatenateSelectedCurrency, convertDateToDDMMYYYY } from '../../helper/helpers';
import XSText from '../ui/typography/XSText';
import XMSText from '../ui/typography/XMSText';
import H3HeaderText from '../ui/typography/H3HeaderText';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as incomingInvoiceAction from './reduxStore/action';
import DownloadDocument from '../common/DownloadDocument';
function PublicImportingInvoice({ importedInvoiceData, checkInvoiceValidation, validationButtonClicked }) {
  const invoiceValidate = async (status) => {
    let payload = {
      invoiceId: importedInvoiceData?._id,
      validationStatus: status,
      status: INVOICE_STATUS.INVOICE_UNPAID
    };
    await checkInvoiceValidation(payload);
    validationButtonClicked(status);
  };

  const handlePaymentAndValiadtionStatus = () => {
    if (importedInvoiceData?.validationStatus === INVOICE_STATUS.AWAITING_VALIDATION) {
      return 'is awaiting validation and is';
    }
    if (
      importedInvoiceData?.validationStatus === INVOICE_STATUS.VALID ||
      importedInvoiceData?.validationStatus === INVOICE_STATUS.NONE
    ) {
      return 'is waiting for payment';
    } else {
      return 'was rejected';
    }
  };

  return (
    <div className="public-invoice-details-container bg-white rounded-2xl px-8 py-6 whitespace-pre-line">
      <MDSubtitleText
        fontWeight="text-bold"
        title={
          importedInvoiceData?.payments?.length === 0
            ? ` Invoice ${importedInvoiceData?.invoiceNumber} \nfrom ${importedInvoiceData?.supplier?.companyName}`
            : `Invoice ${importedInvoiceData?.invoiceNumber} from\n ${importedInvoiceData?.supplier?.companyName}`
        }
      />
      {importedInvoiceData?.payments.length === 0 ? (
        <BaseText
          textColor="text-coral-500"
          className="public-invoice-due-date-detail mt-2"
          title={
            importedInvoiceData?.validationStatus !== INVOICE_STATUS.INVALID
              ? `${handlePaymentAndValiadtionStatus()}  due on ${monthDateSuffix(importedInvoiceData?.dueDate)}`
              : handlePaymentAndValiadtionStatus()
          }
        />
      ) : (
        <>
          <BaseText
            textColor="text-green-500"
            className="public-invoice-due-date-detail mt-2 mb-4"
            title={`was ${
              importedInvoiceData?.status === INVOICE_STATUS.INVOICE_PAID ? 'fully' : 'partially'
            } paid on \n${fDate(importedInvoiceData?.payments[importedInvoiceData?.payments.length - 1].paidDate)}`}
          />

          {(importedInvoiceData?.status === INVOICE_STATUS.INVOICE_PAID ||
            importedInvoiceData?.status === INVOICE_STATUS.PARTIALLY_PAID) && (
            <>
              {importedInvoiceData?.payments.map((payment) => (
                <>
                  <div className="bg-customBg-mainBg py-4 px-4  rounded-lg mb-2">
                    <XMSText className="mb-2" title={payment?.paidDate && convertDateToDDMMYYYY(payment?.paidDate)} />
                    <XSText
                      className="mb-2"
                      textColor="text-green-500"
                      title={
                        payment?.paidAmount &&
                        concatenateSelectedCurrency(importedInvoiceData?.currency, payment?.paidAmount)
                      }
                    />
                    <XMSText title="Added manually" />
                  </div>
                </>
              ))}
            </>
          )}
        </>
      )}
      <div>
        <H3HeaderText
          className="mt-4 mb-2"
          title={`${concatenateSelectedCurrency(importedInvoiceData?.currency, importedInvoiceData?.totalAmount)}`}
        />
        {importedInvoiceData?.paymentStatus === PAYMENT_STATUS_INVOICE.PARTIAL_PAID && (
          <XSText
            textColor={
              importedInvoiceData?.payments?.length > 0 && Number(importedInvoiceData?.pendingAmount) > 0
                ? 'text-green-500'
                : 'text-gray-450'
            }
            title={`${concatenateSelectedCurrency(
              importedInvoiceData?.currency,
              importedInvoiceData?.pendingAmount
            )} remaining`}
          />
        )}
      </div>
      <DownloadDocument
        invoiceId={importedInvoiceData?._id}
        documentName={importedInvoiceData?.invoiceNumber}
        buttonData={{
          id: 'public-importing-invoice-download-pdf-btn',
          bgColor: 'bg-white',
          textColor: 'text-coral-500',
          className: 'incoming-payment-coral-button w-full border border-gray-400 mt-10',
          caption: 'Download PDF'
        }}
      />

      {importedInvoiceData?.validationStatus === INVOICE_STATUS?.AWAITING_VALIDATION && (
        <div className="mt-10 flex flex-col w-full gap gap-2">
          <PrimaryButton
            id="public-importing-invoice-approve-btn"
            type="small"
            className="incoming-payment-coral-button w-full"
            caption="Approve"
            onClick={() => invoiceValidate(INVOICE_STATUS.VALID)}
          />
          <PrimaryButton
            id="public-importing-invoice-reject-btn"
            type="small"
            className="incoming-payment-default-button font-weight-bold border border-gray-400 w-full"
            bgColor="bg-white"
            color="text-gray-300"
            caption="Reject"
            onClick={() => invoiceValidate(INVOICE_STATUS.INVALID)}
          />
        </div>
      )}
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    checkInvoiceValidation: (payload) => dispatch(incomingInvoiceAction.checkInvoiceValidation(payload))
  };
};

export default connect(null, mapDispatchToProps)(PublicImportingInvoice);
PublicImportingInvoice.propTypes = {
  checkInvoiceValidation: PropTypes.func,
  validationButtonClicked: PropTypes.func
};

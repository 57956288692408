import React, { useEffect, useState } from 'react';
import { downloadImage } from '../../helper/helpers';
import PrimaryButton from '../../components/ui/buttons/PrimaryButton';
import { connect } from 'react-redux';
import * as commonAction from '../commonReduxStore/reduxStore/action';

function DownloadDocument({ invoiceId, getPdfSignedUrl, buttonData, documentName }) {
  const [isLoading, setIsLoading] = useState(false);
  const handleDownload = async () => {
    setIsLoading(true);
    const documentUrl = await getPdfSignedUrl({
      invoiceId: invoiceId,
      isDownloadPDF: true
    });
    downloadImage(documentUrl?.data?.pdfSignedURL, documentName);
    setIsLoading(false);
  };
  return (
    <>
      <PrimaryButton
        id={buttonData.id}
        bgColor={buttonData?.bgColor}
        color={buttonData?.textColor}
        className={buttonData?.className}
        caption={buttonData?.caption}
        onClick={() => handleDownload()}
        isLoading={isLoading}
        type="small"
      />
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  getPdfSignedUrl: (getPdfSignedUrlPayload) => dispatch(commonAction.getPdfSignedUrl(getPdfSignedUrlPayload))
});

export default connect(null, mapDispatchToProps)(DownloadDocument);

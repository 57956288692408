import React, { useEffect, useState } from 'react';
import TextInput from '../ui/inputs/TextInput';
import { useForm } from 'react-hook-form';
import PrimaryButton from '../ui/buttons/PrimaryButton';
import ValidationMessage from '../ui/ValidationMessage';
import { API_CODE, FILE_UPLOAD_TYPE, MUI_COLORS, REGEX } from '../../constants/enum';
import { defaultValidationMessage } from '../../helper/helpers';
import { connect } from 'react-redux';
import * as userProfileActions from '../profile/reduxStore/action';
import Success from '../../images/icons/success-icon.svg';
import PropTypes from 'prop-types';
import { getUser } from '../authService/auth';
import Snackbar from '../../components/Snackbar';
import Image from '../../components/Image';
import XSText from '../ui/typography/XSText';
import MSText from '../ui/typography/MSText';
import UploadImage from '../common/UploadImage';
import MDSubtitleText from '../ui/typography/MDSubtitleText';
import StatrysLoader from '../ui/loaders/StatrysLoader';
import * as commonReduxActions from '../commonReduxStore/reduxStore/action';

function ProfileSetting({ getUserProfile, userProfile, uploadProfileImage }) {
  const user = getUser();
  const { register, handleSubmit, errors } = useForm();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [profileData, setProfileData] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const emailRegex = REGEX.EMAIL_REGEX;
  const [saveImage, setSaveImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [uploadError, setUploadError] = useState(false);
  const [acceptedFile, setAcceptedFile] = useState('');
  const [buttonLoader, setButtonLoader] = useState(false);

  const onSubmitProfileDetails = (data) => {
    setButtonLoader(true);
    if (saveImage) {
      getProfileUrl(data);
    } else {
      updateProfileDetails(data);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const fileSelection = (acceptedFiles) => {
    setAcceptedFile(acceptedFiles);
    let selectedimg = acceptedFiles?.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file)
      })
    );
    setSelectedImage(selectedimg);
    setSaveImage(true);
  };

  const getProfileUrl = async (data) => {
    const formData = new FormData();
    formData.append('file', acceptedFile[0]);
    const uploadedProfileResponse = await uploadProfileImage(formData);
    if (uploadedProfileResponse?.status === API_CODE.STATUS_200) {
      updateProfileDetails(data, uploadedProfileResponse?.data?.data?.key);
    } else {
      setUploadError(true);
      setButtonLoader(false);
    }
  };

  const updateProfileDetails = async (data, documentCode) => {
    let payload = {
      email: user?.email ? user?.email : profileData?.email,
      firstName: data?.firstName,
      lastName: data?.lastName,
      phoneNumber: data?.phone,
      documentCode: documentCode ? documentCode : profileData?.documentCode
    };
    const userProfileResponse = await userProfile(payload);
    if (userProfileResponse?.status === API_CODE?.STATUS_200) {
      setMessage({
        ...message,
        contentMessage: `Profile updated successfully`,
        messageTitle: userProfileResponse?.data?.message
      });
      setOpen(true);
      getUserProfileInfo();
      setButtonLoader(false);
    } else {
      setButtonLoader(false);
    }
  };

  const getUserProfileInfo = async () => {
    const getUserInfo = await getUserProfile();
    if (getUserInfo?.status === API_CODE?.STATUS_200) {
      setProfileData(getUserInfo?.data?.data);
      setSelectedImage(
        getUserInfo?.data?.data?.profileImage
          ? [{ preview: getUserInfo?.data?.data?.profileImage, type: FILE_UPLOAD_TYPE.URL }]
          : ''
      );
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getUserProfileInfo();
  }, []);

  return (
    <>
      <Snackbar
        open={open}
        handleClose={handleClose}
        sx={{ bgcolor: MUI_COLORS.LIGHT_GREEN, border: '1px solid', borderColor: MUI_COLORS.GREEN }}
        title={
          <div className="flex">
            <div className="flex items-center">
              <Image className="w-5 h-5" src={Success} />
            </div>
            <div className="ml-4">
              <XSText fontWeight="text-bold" textColor="text-gray-500" title={message?.messageTitle}></XSText>
              <MSText textColor="text-gray-500" title={message?.contentMessage}></MSText>
            </div>
          </div>
        }
      ></Snackbar>
      {isLoading ? (
        <div className="h-full flex items-center">
          <StatrysLoader />
        </div>
      ) : (
        <>
          <div className="setting-form-container bg-white md:ml-6 pt-6 pb-8 md:pl-6 py-6 md:px-0 px-6">
            <MDSubtitleText className="mb-6" fontWeight="text-bold" textColor="text-gray-500" title="Profile" />
            <div className="flex md:flex-row flex-col w-full">
              <div className="md:w-3/5">
                <form id="profile-form" onSubmit={handleSubmit(onSubmitProfileDetails)}>
                  <div>
                    <TextInput
                      defaultValue={profileData?.firstName}
                      textFieldContainerClass="w-full mb-4"
                      name="firstName"
                      label="First name"
                      variant="filled"
                      inputRef={register({
                        required: false
                      })}
                      error={errors.firstName ? true : false}
                      helperText={
                        errors?.firstName && <ValidationMessage title={defaultValidationMessage('First name')} />
                      }
                    />
                    <TextInput
                      defaultValue={profileData?.lastName}
                      textFieldContainerClass="w-full mb-4"
                      name="lastName"
                      label="Last name"
                      variant="filled"
                      inputRef={register({
                        required: false
                      })}
                      error={errors.lastName ? true : false}
                      helperText={
                        errors?.firstName && <ValidationMessage title={defaultValidationMessage('Last name')} />
                      }
                    />
                    <TextInput
                      defaultValue={user?.email ? user?.email : profileData?.email}
                      name="email"
                      textFieldContainerClass="w-full mb-4"
                      label="Email Address"
                      variant="filled"
                      disabled={true}
                      inputRef={register({
                        required: true,
                        pattern: {
                          value: emailRegex,
                          message: 'Please fill in a valid Email Address'
                        }
                      })}
                      error={errors.email ? true : false}
                      helperText={
                        errors.email && <ValidationMessage title={defaultValidationMessage('Email address')} />
                      }
                    />
                    <TextInput
                      defaultValue={profileData?.phoneNumber}
                      name="phone"
                      label="Phone Number"
                      textFieldContainerClass="w-full"
                      inputRef={register({
                        required: false
                      })}
                      variant="filled"
                    />
                  </div>
                </form>
              </div>
              <div className="md:w-2/5 md:order-none order-first">
                <UploadImage
                  saveImage={saveImage}
                  selectedImage={selectedImage}
                  uploadError={uploadError}
                  fileSelection={fileSelection}
                />
              </div>
            </div>
          </div>
          <div className="flex justify-end gap-4 mt-8">
            <PrimaryButton
              id="profile-setting-save-changes-btn"
              isLoading={buttonLoader}
              className="setting-section-button"
              caption="Save Changes"
              onClick={handleSubmit(onSubmitProfileDetails)}
            />
          </div>
        </>
      )}
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    userProfile: (payload) => dispatch(userProfileActions.userProfile(payload)),
    getUserProfile: () => dispatch(userProfileActions.getUserProfile()),
    uploadProfileImage: (uploadedImage) => dispatch(commonReduxActions.uploadDocument(uploadedImage))
  };
};

export default connect(null, mapDispatchToProps)(ProfileSetting);
ProfileSetting.propTypes = {
  userProfile: PropTypes.func,
  getUserProfile: PropTypes.func,
  uploadProfileImage: PropTypes.func
};

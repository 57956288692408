import * as React from 'react';

import PropTypes from 'prop-types';
// @mui
import { useTheme } from '@mui/material/styles';
import { TableRow, TableCell, Typography, Stack } from '@mui/material';
// utils
import { fDate } from '../../utils/formatTime';
// components
import Avatar from '../../components/ui/Avatar';
import { concatenateSelectedCurrency } from '../../helper/helpers';
import SuccessIcon from '../../images/icons/aproove.svg';
import BinIcon from '../../images/icons/bin.svg';
// ----------------------------------------------------------------------

PayTableRow.propTypes = {
  row: PropTypes.object.isRequired,
  selected: PropTypes.bool,
  onSelectRow: PropTypes.func,
  onApproveRow: PropTypes.func,
  onDeleteRow: PropTypes.func
};

export default function PayTableRow({
  row,
  selected,
  onSelectRow,
  onApproveRow,
  onDeleteRow,
  onClickSelectedRow,
  paymentsData
}) {
  const theme = useTheme();

  const { invoiceNumber, dueDate, contact, currency, payments } = row;

  const { paidDate, paymentType, paidAmount, _id } = paymentsData;
  return (
    <TableRow hover selected={selected}>
      {/* <TableCell padding="checkbox">
        <Checkbox checked={selected} onClick={onSelectRow} />
      </TableCell> */}

      <TableCell sx={{ display: 'flex', alignItems: 'center' }} onClick={() => onClickSelectedRow(row?._id)}>
        <div className="mr-4">
          <Avatar avatarImage={contact?.Public} avatarName={contact?.companyName?.charAt(0)} />
        </div>
        <Stack>
          <Typography variant="subtitle2" noWrap>
            {contact?.companyName}
          </Typography>
          {invoiceNumber}
        </Stack>
      </TableCell>

      <TableCell className="cursor-pointer" align="left" onClick={() => onClickSelectedRow(row?._id)}>
        {dueDate ? fDate(dueDate) : ''}
      </TableCell>

      <TableCell className="cursor-pointer" align="left" onClick={() => onClickSelectedRow(row?._id)}>
        {paidDate ? fDate(paidDate) : ''}
      </TableCell>

      <TableCell className="cursor-pointer" align="left" onClick={() => onClickSelectedRow(row?._id)}>
        {paymentType ? paymentType : '-'}
      </TableCell>

      <TableCell className="cursor-pointer text-green-500" align="right" onClick={() => onClickSelectedRow(row?._id)}>
        {paidAmount ? concatenateSelectedCurrency(currency, paidAmount) : '-'}
      </TableCell>

      <TableCell align="right">
        <div className="flex justify-center">
          <div
            className="p-2 bg-green-200 rounded-md cursor-pointer"
            onClick={() => onApproveRow(row._id, paymentsData)}
          >
            <img src={SuccessIcon} alt="success icon" />
          </div>
          <div className="p-2 bg-salmon-500 rounded-md ml-2 cursor-pointer" onClick={() => onDeleteRow(row._id, _id)}>
            <img src={BinIcon} alt="bin icon" />
          </div>
        </div>
      </TableCell>
    </TableRow>
  );
}
